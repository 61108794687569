import moment from "moment";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
import { IConfiguration, IList } from "../models";

/**
 * customizing the toggle button
 */
export enum AuthenticationType {
  ssh = "ssh",
  password = "password",
  sshandpassword = "sshandpassword",
}

export enum RulesType {
  importFile = "importFile",
  exportContactList = "exportContactList",
  importDNCList = "importDNCList",
  exportDNCList = "exportDNCList"
}

export enum ExportContactType {
  selectedlist = "selectedlist",
  name = "name",
}

export enum FileFormatOperationType {
  contains = "contains",
  endswith = "endswith",
  equals = "equals",
  notequals = "notequals",
  regexp = "regexp",
  startswith = "startswith",
}

export type ContactListNameOperationType = FileFormatOperationType;

export enum AppendType {
  appendandupdate = "appendandupdate",
  appendonly = "appendonly",
  flushandappend = "flushandappend",
  newlistsonly = "newlistsonly",
}

export enum AppendTypeForDNC {
  newlistsonly = "newlistsonly",
  appendonly = "appendonly"
}

export enum RecordRetentionDays {
  permanent = "permanent",
  today = "today",
  day1 = "1",
  days2 = "2",
  days3 = "3",
  days4 = "4",
  days5 = "5",
  days6 = "6",
  days7 = "7",
  days8 = "8",
  days9 = "9",
  days10 = "10",
  days11 = "11",
  days12 = "12",
  days13 = "13",
  days14 = "14",
  days15 = "15",
  days16 = "16",
  days17 = "17",
  days18 = "18",
  days19 = "19",
  days20 = "20",
  days21 = "21",
  days22 = "22",
  days23 = "23",
  days24 = "24",
  days25 = "25",
  days26 = "26",
  days27 = "27",
  days28 = "28",
  days29 = "29",
  days30 = "30",
  days45 = "45",
  days60 = "60",
  days90 = "90",
  days180 = "180",
  days360 = "360",
}

export enum RoutesName {
  configuration = "configuration",
  jobsview = "",
  settings = "settings",
  joblogs = "joblogs",
  addserver = "addserver",
  contactlistconfiguration = "contactlistconfiguration",
  help = "help",
}

export const initialConfigurationValue: IConfiguration = {
  automationName: "",
  automationDescription: "",
  schedule: false,
  scheduleDetails: {
    timeZone: timeZone,
    date: moment(),
    description: "",
    scheduleType: "manual",
    cron: "",
    cronText: ""
  },
  serverDetails: {
    userName: "",
    authenticationType: AuthenticationType.password,
    password: "",
    fileServer: "",
    directory: "",
    publicKey: "",
    additionalAttributes: {
      deleteFiles: false,
      clearAll: true,
      isMovefiles: false,
      moveFiles: {
        folderName: "",
      },
      traceConnection: true,
    },
  },
  rules: {
    ruleType: RulesType.importFile,
    [RulesType.importFile]: {
      fileFormatOperation: FileFormatOperationType.equals,
      fileFormatName: "",
      appendType: AppendType.newlistsonly,
      contactList: "",
      contactListId: "",
      phoneColumns: [],
      contactListConfiguration: {
        division: { id: "" },
        useSpecificationFile: false,
        useImportTemplate: false,
        trimWhitespace: true,
        columnNames: [],
        phoneColumns: [{ columnName: "", type: "", callableTimeColumn: "" }],
      },
      fileSpecificationTemplateId: "",
      importTemplateId: "",
    },
    [RulesType.exportContactList]: {
      contactList: "",
      outputFileFormat: "",
      phoneColumns: [],
    },
  },
};

export const existingConfigurationValue: IConfiguration = {
  automationName: "Existing Automation Name",
  automationDescription: "Existing Automation Description",
  schedule: true,
  scheduleDetails: {
    timeZone: "Europe/Luxembourg",
    date: moment(),
    description:
      "some testing description as been added to the description field",
    scheduleType: "manual",
    cron: "26 10 29 1 ? 2024",
  },
  serverDetails: {
    userName: "Sftpusername",
    authenticationType: AuthenticationType.password,
    password: "newpassword",
    fileServer: "sftp://testserver.com/",
    directory: "newdir/contacts",
    publicKey: "RSA PUBLIC KEY",
    additionalAttributes: {
      deleteFiles: false,
      clearAll: false,
      isMovefiles: true,
      moveFiles: {
        folderName: "string;",
      },
      traceConnection: true,
    },
  },
  rules: {
    ruleType: RulesType.importFile,
    [RulesType.importFile]: {
      fileFormatOperation: FileFormatOperationType.equals,
      fileFormatName: "list_data",
      appendType: AppendType.appendonly,
      contactList: "CL1",
      contactListId: "8e76947c-40ba-475d-8143-dee3fe39a763",
      phoneColumns: [
        {
          columnName: "PhoneNumber",
          type: "Cell",
        },
      ],
      contactListConfiguration: {
        division: { id: "divisionid1" },
        useSpecificationFile: false,
        useImportTemplate: false,
        trimWhitespace: true,
        phoneColumns: [
          { columnName: "phone", type: "phone", callableTimeColumn: "phone" },
        ],
      },
    },
  },
};

export const FileFormatOperationTypeList: Array<
  IList<FileFormatOperationType>
> = [
  {
    displayName: "Contains",
    value: FileFormatOperationType.contains,
  },
  {
    displayName: "Ends With",
    value: FileFormatOperationType.endswith,
  },
  {
    displayName: "Equals",
    value: FileFormatOperationType.equals,
  },
  {
    displayName: "Not Equals",
    value: FileFormatOperationType.notequals,
  },
  {
    displayName: "RegExp",
    value: FileFormatOperationType.regexp,
  },
  {
    displayName: "Starts With",
    value: FileFormatOperationType.startswith,
  },
];

export const ContactListNameOperationTypeList: Array<
  IList<ContactListNameOperationType>
> = JSON.parse(JSON.stringify(FileFormatOperationTypeList));

export const AppendTypeList: Array<IList<AppendType>> = [
  // {
  //   displayName: "Append And Update",
  //   value: AppendType.appendandupdate,
  // },
  {
    displayName: "Append Only",
    value: AppendType.appendonly,
  },
  {
     displayName: "Flush and Append",
    value: AppendType.flushandappend,
  },
  {
    displayName: "New Lists Only",
    value: AppendType.newlistsonly,
  },
];

export const AppendTypeListForDNC: Array<IList<AppendType>> = [
  {
    displayName: "Append Only",
    value: AppendType.appendonly,
  },
  {
    displayName: "New Lists Only",
    value: AppendType.newlistsonly,
  },
];

export const RecordRetentionDaysList: Array<IList<RecordRetentionDays>> = [
  { displayName: "Permanent", value: RecordRetentionDays.permanent },
  { displayName: "Today", value: RecordRetentionDays.today },
  { displayName: "1 day", value: RecordRetentionDays.day1 },
  { displayName: "2 days", value: RecordRetentionDays.days2 },
  { displayName: "3 days", value: RecordRetentionDays.days3 },
  { displayName: "4 days", value: RecordRetentionDays.days4 },
  { displayName: "5 days", value: RecordRetentionDays.days5 },
  { displayName: "6 days", value: RecordRetentionDays.days6 },
  { displayName: "7 days", value: RecordRetentionDays.days7 },
  { displayName: "8 days", value: RecordRetentionDays.days8 },
  { displayName: "9 days", value: RecordRetentionDays.days9 },
  { displayName: "10 days", value: RecordRetentionDays.days10 },
  { displayName: "11 days", value: RecordRetentionDays.days11 },
  { displayName: "12 days", value: RecordRetentionDays.days12 },
  { displayName: "13 days", value: RecordRetentionDays.days13 },
  { displayName: "14 days", value: RecordRetentionDays.days14 },
  { displayName: "15 days", value: RecordRetentionDays.days15 },
  { displayName: "16 days", value: RecordRetentionDays.days16 },
  { displayName: "17 days", value: RecordRetentionDays.days17 },
  { displayName: "18 days", value: RecordRetentionDays.days18 },
  { displayName: "19 days", value: RecordRetentionDays.days19 },
  { displayName: "20 days", value: RecordRetentionDays.days20 },
  { displayName: "21 days", value: RecordRetentionDays.days21 },
  { displayName: "22 days", value: RecordRetentionDays.days22 },
  { displayName: "23 days", value: RecordRetentionDays.days23 },
  { displayName: "24 days", value: RecordRetentionDays.days24 },
  { displayName: "25 days", value: RecordRetentionDays.days25 },
  { displayName: "26 days", value: RecordRetentionDays.days26 },
  { displayName: "27 days", value: RecordRetentionDays.days27 },
  { displayName: "28 days", value: RecordRetentionDays.days28 },
  { displayName: "29 days", value: RecordRetentionDays.days29 },
  { displayName: "30 days", value: RecordRetentionDays.days30 },
  { displayName: "45 days", value: RecordRetentionDays.days45 },
  { displayName: "60 days", value: RecordRetentionDays.days60 },
  { displayName: "90 days", value: RecordRetentionDays.days90 },
  { displayName: "180 days", value: RecordRetentionDays.days180 },
  { displayName: "360 days", value: RecordRetentionDays.days360 },
];

export enum PhoneType {
  cell = "cell",
  daytime = "daytime",
  evening = "evening",
  home = "home",
  work = "work",
}

export enum EmailType {
  personal = "personal",
  work = "work",
}

export const tempJobViewData = [
  {
    contactId: "1",
    fileName: "fileName1",
    serverName: "serverName1",
    typeOfUpload: "typeOfUpload1",
    userAgent: "userAgent1",
    startDate: "24-1-2011",
    endDate: "24-1-2011",
    latestUpdate: "latestUpdate1",
  },
  {
    contactId: "2",
    fileName: "fileName2",
    serverName: "serverName2",
    typeOfUpload: "typeOfUpload2",
    userAgent: "userAgent2",
    startDate: "24-2-2022",
    endDate: "24-1-2011",
    latestUpdate: "latestUpdate2",
  },
  {
    contactId: "3",
    fileName: "fileName3",
    serverName: "serverName3",
    typeOfUpload: "typeOfUpload3",
    userAgent: "userAgent3",
    startDate: "24-3-2033",
    endDate: "24-1-2011",
    latestUpdate: "latestUpdate3",
  },
  {
    contactId: "4",
    fileName: "fileName4",
    serverName: "serverName4",
    typeOfUpload: "typeOfUpload4",
    userAgent: "userAgent4",
    startDate: "24-4-2044",
    endDate: "24-1-2011",
    latestUpdate: "latestUpdate4",
  },
  {
    contactId: "5",
    fileName: "fileName5",
    serverName: "serverName5",
    typeOfUpload: "typeOfUpload5",
    userAgent: "userAgent5",
    startDate: "24-5-2055",
    endDate: "24-1-2011",
    latestUpdate: "latestUpdate5",
  },
  {
    contactId: "6",
    fileName: "fileName6",
    serverName: "serverName6",
    typeOfUpload: "typeOfUpload6",
    userAgent: "userAgent6",
    startDate: "24-6-2066",
    endDate: "24-1-2011",
    latestUpdate: "latestUpdate6",
  },
  {
    contactId: "7",
    fileName: "fileName7",
    serverName: "serverName7",
    typeOfUpload: "typeOfUpload7",
    userAgent: "userAgent7",
    startDate: "24-7-2077",
    endDate: "24-1-2011",
    latestUpdate: "latestUpdate7",
  },
  {
    contactId: "8",
    fileName: "fileName8",
    serverName: "serverName8",
    typeOfUpload: "typeOfUpload8",
    userAgent: "userAgent8",
    startDate: "24-8-2088",
    endDate: "24-1-2011",
    latestUpdate: "latestUpdate8",
  },
  {
    contactId: "9",
    fileName: "fileName9",
    serverName: "serverName9",
    typeOfUpload: "typeOfUpload9",
    userAgent: "userAgent9",
    startDate: "24-9-2099",
    endDate: "24-1-2011",
    latestUpdate: "latestUpdate9",
  },
];
