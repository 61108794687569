/*
*   NOTE: This sample uses ES6 features
*/
import config, { accessToken, getEnv } from '../../../config'
import { getStorageItem, setStorageItem } from '../../../services/applicationStorage';
const platformClient = window.platformClient;

const appConfig = config;
// JQuery Alias
const $ = window.$;

// Relative path to wizard page from config's redirectUri
//const WIZARD_PAGE = "/index.html";

const pageSize = 500;
/**
 * WizardApp class that handles everything in the App.
 */
class WizardApp {
    constructor(redirectUri) {
        this.pcEnvironment = null;
        this.org = null;

        // PureCloud Javascript SDK clients
        this.platformClient = platformClient;
        this.purecloudClient = this.platformClient.ApiClient.instance;
        this.purecloudClient.setPersistSettings(true, appConfig.integrationType);
        this.redirectUri = redirectUri;

        // PureCloud API instances
        this.usersApi = new this.platformClient.UsersApi();
        this.integrationsApi = new this.platformClient.IntegrationsApi();
        this.authApi = new this.platformClient.AuthorizationApi();
        this.oAuthApi = new this.platformClient.OAuthApi();
        this.organizationApi = new this.platformClient.OrganizationApi();

        // Language default is english
        // Language context is object containing the translations
        this.language = appConfig.defaultLangTag;

        this.integrationType = appConfig.integrationType;

        this.appName = appConfig.purecloud.applicationName;
        this.appUrl = appConfig.purecloud.baseUrl;

        this.installationData = appConfig.provisioningInfo;
    }

    //// =======================================================
    ////      ENTRY POINT
    //// =======================================================
    start() {
        return new Promise((resolve, reject) => {
            this._setupClientApp()
                .then(() => {
                    return this._pureCloudAuthenticate()
                })
                .then((data) => {
                    if (data && data.accessToken) {
                        // sessionStorage.setItem('purecloud-csp-token', data.accessToken);
                        setStorageItem('purecloud-csp-token', data.accessToken, true, sessionStorage);
                        this.purecloudClient.setAccessToken(data.accessToken);
                    }
                    console.debug('Setup success');
                    return this.organizationApi.getOrganizationsMe()
                }).then((orgData) => {
                    this.org = orgData
                    console.debug(this.org)
                    sessionStorage.setItem('orgId', this.org.id)
                    sessionStorage.setItem('orgName', this.org.thirdPartyOrgName)

                    return resolve();
                })
                .catch((err) => {
                    console.error(err);
                    reject(err)
                });
        });
    }

    /**
     * First thing that needs to be called to setup up the PureCloud Client App
     */
    _setupClientApp() {
        // Snippet from URLInterpolation example: 
        // https://github.com/MyPureCloud/client-app-sdk
        const queryString = window.location.search.substring(1);
        const pairs = queryString.split('&');
        let pcEnv = null;
        let langTag = null;

        for (let i = 0; i < pairs.length; i++) {
            const currParam = pairs[i].split('=');

            if (currParam[0] === 'langTag') {
                langTag = currParam[1];
            } else if (currParam[0] === 'hostOrigin') {
                pcEnv = this.getDomainName(currParam[1]);
            }
        }

        // Stores the query parameters into sessionStorage
        // If query parameters are not provided, try to get values from sessionStorage
        // Default values if it does not exist.
        if (pcEnv) {
            setStorageItem('purecloud-csp-env', pcEnv, true, sessionStorage);
        } else if (sessionStorage.getItem('purecloud-csp-env') && sessionStorage.getItem('purecloud-csp-env') != "undefined") {
            pcEnv = getStorageItem('purecloud-csp-env', true, sessionStorage);
        } else {
            // Use default PureCloud region
            pcEnv = appConfig.defaultPcEnv;
            setStorageItem('purecloud-csp-env', pcEnv, true, sessionStorage);
        }

        this.pcEnvironment = pcEnv;
        console.debug("Environment:" + this.pcEnvironment);

        if (langTag) {
            sessionStorage.setItem('purecloud-csp-langTag', langTag);
        } else if (sessionStorage.getItem('purecloud-csp-langTag')) {
            langTag = sessionStorage.getItem('purecloud-csp-langTag');
        } else {
            // Use default Language
            langTag = appConfig.defaultLangTag;
        }
        this.language = langTag;

        console.debug("Language:" + this.language);

        return new Promise((resolve) => { resolve() })

        // Get the language context file and assign it to the app
        // For this example, the text is translated on-the-fly.
        // return new Promise((resolve, reject) => {
        //     const fileUri = '../languages/' + this.language + '.json';
        //     $.getJSON(fileUri)
        //         .done(data => {
        //             this.displayPageText(data);
        //             resolve();
        //         })
        //         .fail(xhr => {
        //             //reject(new Error(`Language file not found - "${this.language}.json"`));
        //             console.error(`Language file not found - "${this.language}.json. Loading default language"`)
        //             this.language = 'en-us'
        //             sessionStorage.setItem('purecloud-csp-langTag', 'en-us');
        //             this.loadDefaultLanguage()
        //             resolve();
        //         });
        // });
    }

  

    loadDefaultLanguage() {
        $.getJSON('../languages/en-us.json')
            .done(data => {
                this.displayPageText(data);
            })
            .fail(xhr => {
                //reject(new Error(`Language file not found - "${this.language}.json"`));
                console.error(`Language file not found - "${this.language}.json"`)
            });
    }

    /**
    * Renders the proper text language into the web pages
    * @param {Object} text  Contains the keys and values from the language file
    */
    displayPageText(text) {
        $(document).ready(() => {
            for (let key in text) {
                if (!text.hasOwnProperty(key)) continue;
                $("." + key).text(text[key]);
            }
        });
    }

    /**
     * Authenticate to PureCloud (Implicit Grant)
     * @return {Promise}
     */
    _pureCloudAuthenticate() {
        console.debug('_pureCloudAuthenticate clientId =', appConfig.purecloud.clientId);
        console.debug('_pureCloudAuthenticate redirectUri =', this.redirectUri);
        console.debug('_pureCloudAuthenticate pcEnvironment =', this.pcEnvironment);

        this.purecloudClient.setEnvironment(this.pcEnvironment);
        return this.purecloudClient.loginImplicitGrant(
            appConfig.purecloud.clientId,
            this.redirectUri,
            { state: ('pcEnvironment=' + this.pcEnvironment) });
    }

    /**
    * Checks if the product is available in the current Purecloud org by
    * validating the integration type being enabled for the org
    * @return {Promise.<Boolean>}
    */
    validateProductAvailability() {
        // premium-app-usertemplates         
        return this.getEntities(this.integrationsApi, 'getIntegrationsTypes')
            .then((entities) => {
                if (entities.filter((integType) => integType.id === this.integrationType)[0]) {
                    console.debug("PRODUCT AVAILABLE");
                    return (true);
                } else {
                    console.debug("PRODUCT NOT AVAILABLE");
                    return (false);
                }
            });
    }

    /**
    * Checks if any configured objects are still existing. 
    * This is based on the appName
    * @returns {Promise.<Boolean>} If any installed objects are still existing in the org. 
    */
    isExisting() {
        const promiseArr = [];

        promiseArr.push(this.getExistingRoles());
        //promiseArr.push(this.getExistingAuthClients());

        return Promise.all(promiseArr)
            .then((results) => {
                console.debug('isExisting, results = ' + JSON.stringify(results, null, 3));
                return results[0].length;
            });
    }

    /**
    * Get existing roles in purecloud based on appName
    * @returns {Promise.<Array>} PureCloud Roles
    */
    getExistingRoles() {
        const authOpts = {
            'name': appConfig.purecloud.applicationName.replace(/\(/g, '\\(').replace(/\)/g, '\\)') + '*', // Wildcard to work like STARTS_WITH, need to escape ) and (
            'userCount': false
        };
        return this.getEntities(this.authApi, 'getAuthorizationRoles', authOpts);
    }


    /**
     * Get existing authetication clients based on the prefix
     * @returns {Promise.<Array>} Array of PureCloud OAuth Clients
     */
    getExistingAuthClients() {
        return this.getEntities(this.oAuthApi, 'getOauthClients')
            .then((entities) => {
                return entities.filter(entity => entity.name && entity.name.startsWith(this.appName +' OAuth'));
            });
    }

    /**
     * Get details of the current user
     * @return {Promise.<Object>} PureCloud User data
     */
    getUserDetails() {
        const opts = { 'expand': ['authorization', 'orgproducts'] };
        return this.usersApi.getUsersMe(opts);
    }

    /**
    * Get all the products of R2S
    * @return {Promise.<Object>} R2S products
    */
    async getProducts() {

        let user = await this.getUserDetails()
        let products = user["orgProducts"]
        let allProducts = await this.getAllProducts()

        let enabledProducts = []

        for (let product of allProducts.Items) {
            if (products.filter(x => x.id === product.productId).length !== 0) {
                enabledProducts.push(product)
            }
        }

        return enabledProducts
    }

    getAllProducts() {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'GET',
                headers: {
                    'token': sessionStorage.getItem('purecloud-csp-token'),
                    'env': this.pcEnvironment,
                    'tokensource': 'purecloud'
                },
                url: appConfig.purecloud.baseUrl + '/products',
                contentType: 'application/json',
                success: function (result) {
                    console.debug('get products');
                    resolve(result)
                },
                error: function (xhr, status, error) {
                    console.error('getProducts, xhr = ' + JSON.stringify(xhr, null, 3));
                    console.error('getProducts, status = ' + JSON.stringify(status, null, 3));
                    console.error('getProducts, error = ' + JSON.stringify(error, null, 3));

                    reject("Error in getting R2S products.");
                }
            });
        });
    }

    //// =======================================================
    ////      PROVISIONING
    //// =======================================================

    /**
     * Final Step of the installation wizard. 
     * Create the PureCloud objects defined in provisioning configuration
     * The order is important for some of the PureCloud entities.
     */
    installConfigurations() {
        try {
            console.debug('Installing configurations');

            $.LoadingOverlay("show", {
                image: "",
                fontawesomeResizeFactor: 2.5,
                fontawesome: "fa fa-spinner fa-spin",
                fontawesomeColor: '#DDDDDD',
                fontawesomeOrder: 1,
                text: "Configuring your App",
                textResizeFactor: 0.17,
                textOrder: 2,
                textClass: "adjust-text",
                background: "rgba(240, 240, 255, 0.9)"
            });
    
            // Get the app instance from integration
            return this.getAppInstance()
                // Create OAuth client after role (required) and pass to server
                .then(async () => {
                    this.logInfo('Ensuring SFTP roles are already available'); 
                    let existingRoles = await this.getExistingRoles();
                    if(existingRoles.length == 4) {
                        this.logInfo('SFTP roles are already available'); 
                        return existingRoles.find((role)=> role.name == appConfig.purecloud.applicationName)
                    }
                    let roles = [];
                    this.installationData.roles.forEach(async(role) => {
                        const roleBody = {
                            name: role.name,
                            description: role.description,
                            permissionPolicies: role.permissionPolicies
                        };
                        roles.push(this.addRole(roleBody));
                    });
                    let roleData = await Promise.all(roles);
                    return roleData.find((role)=> role.name == appConfig.purecloud.applicationName);  
                })
              
    
                //Create OAuth client after role (required) and pass to server
                .then(async (role) => {
                    return await this.addAuthClient(role)}
                )
    
                // Add organization in User Templates configuration
                .then((oauth) => {
                    this.addOrganization(oauth)}
                )
    
                // When everything's finished, log a success message.
                .then(() => {
                    this.logInfo(`
                    The request has been successfully placed!
                    Please wait for a minute to complete the installation.`);
                    setTimeout(function () { $.LoadingOverlay("hide"); }, 3000);
                }).catch((err) => {
                    this.logInfo('Error occured!');
                    setTimeout(function () { $.LoadingOverlay("hide"); }, 1500);
                    throw err;
                });
        }
        catch(e){
            throw e;
        }      
    }

    getAppInstance() {
        return this.getEntities(this.integrationsApi, 'getIntegrations')
            .then(entities => {
                return (entities.find(entity => entity.integrationType.id === this.integrationType && entity.name === this.appName));
            })
            .then(integration => {
                if (!(integration && integration.id)) {
                    throw new Error(`Could not find integration - "${this.appName}".`);
                }
                return integration;
            });
    }

    /**
     * Add PureCLoud role based on installation data
     * @returns {Promise}
     */
    async addRole(roleData) {
        console.debug('Adding role');
    
        
        this.logInfo('Creating role ' + roleData.name);      

        let role;
       

        // Create the role
        return this.authApi.postAuthorizationRoles(roleData)
            .then((data) => {
                this.logInfo('Created role ' + roleData.name);
                role = data;
                return this.getUserDetails();
            })
            .then((user) => {
                // Assign the role to the user
                // Required before you can assign the role to an Auth Client.
                return this.authApi.putAuthorizationRoleUsersAdd(role.id, [user.id]);
            })
            .then(() => {
                this.logInfo('Assigned role ' + appConfig.purecloud.applicationName + ' to user');
                return role;
            })
            .catch((err) => {
                console.error('Role creation error:' + JSON.stringify(err, null, 3));
                throw err;
            });
        

      
    }

    /**
     * Add PureCLoud instance based on installation data
     * @returns {Promise.<Array>} PureCloud OAuth objects
     */
    async addAuthClient(role) {
        console.debug('Adding oauth client');
        this.logInfo('Creating oauth client');

        const oauthClient = {
            name: this.installationData.oauth.name,
            description: this.installationData.oauth.description + sessionStorage.getItem('orgName'),
            roleIds: [role.id],
            authorizedGrantType: "CLIENT_CREDENTIALS"
        };

        let existingOAuth = await this.getExistingAuthClients();
        if(existingOAuth.length) return existingOAuth[0];

        return this.oAuthApi.postOauthClients(oauthClient)
            .then((data) => {
                this.logInfo(`Created OAuth client "${this.installationData.oauth.name}"`);
                return data;
            })
            .catch((err) => {
                console.error('Oauth creation error:' + JSON.stringify(err, null, 3));
                throw err;
            })
    }

    //// =======================================================
    ////      ORGANIZATION
    //// =======================================================

    addOrganization(oauth) {
        console.debug('Adding organization');
        this.logInfo('Adding customer information in data base');

        const orgID = sessionStorage.getItem('orgId');
        const orgName = sessionStorage.getItem('orgName')
        console.debug(appConfig.endpoints.apiEndpoint + '/addOrg')

        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                headers: {
                    token: accessToken(),
                    tokensource: "purecloud",
                    env: getEnv(),
                },
                url: appConfig.endpoints.apiEndpoint + '/addOrg' ,
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({ CLIENT_ID: oauth.id, CLIENT_SECRET: oauth.secret, orgName: orgName, version: "AF", orgId: orgID }),
                success: function (result) {
                    console.debug('Added customer org successfully');
                    sessionStorage.setItem('sw-tenant', result?.data?.tenantId);                  
                    //this.logInfo('Added customer org successfully')
                    resolve()
                },
                error: function (xhr, status, error) {
                    console.error('addOrganization, xhr = ' + JSON.stringify(xhr, null, 3));
                    console.error('addOrganization, status = ' + JSON.stringify(status, null, 3));
                    console.error('addOrganization, error = ' + JSON.stringify(error, null, 3));
                    //this.logInfo('Error in adding organisation.')
                    reject({body: {message: "Error in adding organisation."}});               
                }
            });
        });
    }


    //// =======================================================
    ////      DISPLAY/UTILITY FUNCTIONS
    //// =======================================================

    /**
     * Shows an overlay with the specified data string
     * @param {string} data 
     */
    logInfo(data) {
        if (!data || (typeof (data) !== 'string')) data = "";

        $.LoadingOverlay("text", data);
    }


    //// =======================================================
    ////      UTILITIES
    //// =======================================================

    getEntities(api, func, options) {
        const entities = [];
        let pageNumber = 1;

        const getEntitiesWorker = () => {
            this.purecloudClient.setAccessToken(getStorageItem('purecloud-csp-token', true, sessionStorage));
            return api[func]({ ...options, pageSize, pageNumber })
                .then((data) => {
                    console.debug(`${func}, pageSize = ${pageSize}, pageNumber = ${pageNumber}, entities = ${data.entities.length}`);
                    entities.push(...data.entities);
                    return (pageNumber++ < data.pageCount) ? getEntitiesWorker() : entities;
                });
        }
        return getEntitiesWorker();
    }

    getDomainName(url) {
        url = decodeURIComponent(url);
        const domainName = url.replace('https://apps.', '').replace('http://', '').split(/[/?#]/)[0];
        return domainName;
    }

}


export default WizardApp;