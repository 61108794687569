import { useEffect, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import moment from "moment";

const DateTime = (props) => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [labelName, setLabelName] = useState("");

  useEffect(() => {
    setLabelName(props.labelName);
    if (props.selectDate) {
      setSelectedDate(props.selectDate);
    }
    if (props.startDate) {
      setSelectedDate(props.startDate);
    }
  }, [props.selectDate]);

  const handleDateChange = (e) => {
    setSelectedDate(e);
    props.selectedDate(e);
  };

  if (labelName !== "End Date") {
    return (
      <>
        <DateTimePicker
          label={labelName}
          sx={{ width: "100%" }}
          value={selectedDate}
          views={["year", "month", "day", "hours", "minutes", "seconds"]}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          // minDate={moment()} // Set the minimum date to the current date
          onChange={handleDateChange}
          disabled={props.disable}
        />
      </>
    );
  } 

  return (
    <>
      <DateTimePicker
        label={labelName}
        sx={{ width: "100%" }}
        value={selectedDate}
        views={["year", "month", "day"]}
        // minDate={moment(ob)} // Set the minimum date to the current date
        onChange={handleDateChange}
        disabled={props.disable}
      />
    </>
  );
};

export default DateTime;
