import React from "react";
import styled from "styled-components";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const LoaderDiv = styled.div`
    margin-top: 30px;
    text-align: center;
`
const Icon = styled.i`
    font-size: 70px;
`

const Loader = () => {
    return (
        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
}

export default Loader;