import { create } from "zustand";
import { IJobLog } from "../../models";

interface IJobLogsStore {
  data: Array<IJobLog>;
  setJobLogs: (logs: Array<IJobLog>) => void;
}

const useJobLogsStore = create<IJobLogsStore>((set) => ({
  data: [],
  setJobLogs: (logs) => set((store) => ({ data: logs })),
}));

export default useJobLogsStore;
