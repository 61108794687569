import config from "../config";
import { useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Util
import { getParameterByName } from "../services/utils";
import {
  setStorageItem,
  getStorageItem,
  removeStorageItem,
} from "../services/applicationStorage";

// Services
import { getOrganization } from "../services/purecloud";
import { validateGCApp } from "../services/subscription";

const Main = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const purecloudInitialization = async () => {
      let env = new URLSearchParams(location.search).get("environment"); // Future change to PCenv
      // env = "inindca.com";
      if (!env) {
        let sessionEnv = getStorageItem(
          "purecloud-csp-env",
          true,
          sessionStorage
        );
        if (sessionEnv) {
          env = sessionEnv;
        } else {
          env = config.defaultPcEnv;
        }
      }

      setStorageItem("purecloud-csp-env", env, true, sessionStorage);
      console.log("Main.purecloud.init:", env);

      if (getParameterByName("access_token")) {
        setStorageItem(
          "purecloud-csp-token",
          getParameterByName("access_token"),
          true,
          sessionStorage
        );
      }

      const envFromSession = getStorageItem(
        "purecloud-csp-env",
        true,
        sessionStorage
      );
      const envFromSessionSanitized =
        typeof envFromSession === "string" && envFromSession.trim().length > 0
          ? envFromSession.trim()
          : "";
      const envToken = getStorageItem(
        "purecloud-csp-token",
        true,
        sessionStorage
      );
      const envTokenSanitized =
        typeof envToken === "string" && envToken.trim().length > 0
          ? envToken.trim()
          : "";

      if (envToken) {
        try {
          const organization = await getOrganization(
            envFromSessionSanitized,
            envTokenSanitized
          );
          setStorageItem(
            "organization-id",
            organization.id || "",
            true,
            sessionStorage
          );
          setStorageItem(
            "organization-name",
            organization.name || "",
            true,
            sessionStorage
          );
          navigate("/home");
        } catch (error) {
          if (error.status === 401 && error.code === "bad.credentials") {
            //handle expired token
            removeStorageItem("purecloud-csp-token", true, sessionStorage);
            console.log("Invalid token - Redirecting to login page");
            navigate("/login");
          } else {
            navigate("/unauthorized", error.message);
          }
        }
      } else navigate("/login");
    };

    const pureconnectInitialization = () => {
      if (sessionStorage.getItem("pureconnect-csrf-token")) {
        navigate("/home");
      } else {
        navigate("/login");
      }
    };

    switch (config.product) {
      case "purecloud":
        purecloudInitialization();
        break;
      case "pureconnect":
        pureconnectInitialization();
        break;
      case "pureengage":
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, []);

  return <Fragment></Fragment>;
};

export default Main;
