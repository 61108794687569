const crypto = require('crypto')
const utils = require('./utils')

// Subscription data is replicated from us-east-1 to us-west-2 & eu-central-1
const endpoints = [
	{
		'region': 'us-east-1',
		'url': 'https://api.customer-subscription-manager.genesyspsdevops.com'
	},
	{
		'region': 'us-west-2',
		'url': 'https://api.us-west-2.customer-subscription-manager.genesyspsdevops.com'
	},
	{
		'region': 'eu-central-1',
		'url': 'https://api.eu-central-1.customer-subscription-manager.genesyspsdevops.com'
	}
]

// Retrieves Subscription API endpoint uri
const getUri = (endpoint) => {
	switch (process.env.REACT_APP_CUSTOM_ENV.trim()) {
		case 'test': return `${endpoint.url}/test`
		case 'production': return `${endpoint.url}/prod`
		default: return `${endpoint.url}/dev`
	}
}

export const validateGCApp = async (token, env, organizationId, solutionName, licenseName) => {
	const body = {
		deploymentId: organizationId,
		productName: solutionName
	}
	if (licenseName) body.licenseName = licenseName

	for (const endpoint of endpoints) {
		try {
			console.log('validateGCApp.querying.endpoint:', endpoint.url)
			const subscriptionValidationResponse = await utils.fetchWrapper(`${getUri(endpoint)}/subscription/validate`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					'tokensource': 'purecloud',
					env,
					token
				},
				body: JSON.stringify(body)
			})
			return subscriptionValidationResponse
		} catch (error) {
			console.log('error:', error)
			if (error.status < 500) throw error // Try the next endpoint only if we get a server error. If a subscription is invalid, we error out
		}
	}
	throw { status: 500, message: 'No subscription endpoints available' }
}

/**
 * Validates an App Subscription.
 * 
 * @param {string} subscriptionId PS Subscription ID binding the solution license to a customer deployment. Retrieved from Customer Subscription Manager tool.
 * @param {string} solutionName Solution Name
 * 
 */

export const validateApp = async (subscriptionId, solutionName) => {
	const body = { productName: solutionName }
	for (const endpoint of endpoints) {
		try {
			console.log('validateApp.querying.endpoint:', endpoint.url)
			const subscriptionValidationResponse = await utils.fetchWrapper(`${getUri(endpoint)}/subscription/validate`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					'signature': crypto.createHmac('sha256', subscriptionId).update(JSON.stringify(body)).digest('base64')
				},
				body: JSON.stringify(body)
			})
			return subscriptionValidationResponse
		} catch (error) {
			console.log('error:', error)
			if (error.status < 500) throw error // Try the next endpoint only if we get a server error. If a subscription is invalid, we error out
		}
	}
	throw { status: 500, message: 'No subscription endpoints available' }
}

export default {
	validateGCApp,
	validateApp
}