import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Alert,
  AlertColor,
  Backdrop,
  CircularProgress,
  Paper,
  Snackbar,
} from "@mui/material";
import { useState, useEffect, ReactNode } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridRowEditStopReasons,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import { IJobLog } from "../../models";
import useJobLogsStore from "../../state-management/jobLogs/store";
import moment from "moment";

const JobLogs = () => {
  const [rows, setRows] = useState<Array<IJobLog>>([] as Array<IJobLog>);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>();
  const [isFormDataLoading, setFormDataLoading] = useState<boolean>(false);
  const [getSnackDetails, setSnackDetails] = useState({
    open: false,
    message: (<></>) as ReactNode,
    severity: "success",
  });

  const { data: jobLogsData } = useJobLogsStore();
  //This hooks is used to load initial data
  useEffect(() => {
    (async () => {
      try {
        setFormDataLoading(true);
        setRows(jobLogsData || []);
        setFormDataLoading(false);
      } catch (err) {
        console.error(err);
        setRows([]);
        setFormDataLoading(false);
        setSnackDetails({
          open: true,
          message: <>Please try again</>,
          severity: "error",
        });
      }
    })();
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function CustomToolbar(props) {
    const handleDelete = () => {
      const selectedIDs = new Set(rowSelectionModel);
      // you can call an API to delete the selected IDs
      // and get the latest results after the deletion
      // then call setRows() to update the data locally here
      setRows((r) => r.filter((x) => !selectedIDs.has(x.id)));
    };

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<DeleteIcon />}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </GridToolbarContainer>
    );
  }

  const defaultWidthColumns = 200;
  const columns = [
    {
      field: "startTime",
      headerName: "Start Time",
      width: defaultWidthColumns,
      valueGetter: (params: GridValueGetterParams) =>
        moment(params.row.startTime).format("MM/DD/YYYY, h:mm:ss a"),
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: defaultWidthColumns,
      valueGetter: (params: GridValueGetterParams) =>
        moment(params.row.endTime).format("MM/DD/YYYY, h:mm:ss a"),
    },
    {
      field: "result",
      headerName: "Job Result",
      flex: 0,
      minWidth: defaultWidthColumns - 100,
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      minWidth: defaultWidthColumns + 200,
      valueGetter: (params: GridValueGetterParams) => {
        //return params.row.message.map((v) =>( <p> {v + "\n"}</p>));
        return params.row.message;
      },
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }} className="jobs-view">
      <div className="m-2 text">Job Logs</div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFormDataLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFormDataLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          getRowId={(_row) => uuidv4()}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
        />
      </Box>
      {getSnackDetails.open && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={getSnackDetails.open}
          autoHideDuration={5000}
          onClose={(_event, reason?: string) => {
            if (reason === "clickaway") {
              return;
            }
            setSnackDetails({ open: false, message: <></>, severity: "error" });
          }}
        >
          <Alert
            severity={getSnackDetails.severity as AlertColor}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {getSnackDetails.message as ReactNode}
          </Alert>
        </Snackbar>
      )}
    </Paper>
  );
};

export default JobLogs;
