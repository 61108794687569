import { useEffect, useState } from "react";
import Header from "../common/header/index";
import { MainDiv, ParentDiv, WizardContent } from '../styles'
import WelcomeGreet from "../common/welcome-greet/welcome-greet";
import ProgressBar from "../common/progressBar/index";
import Loader from "../common/loader";
import ProductStatus from "../common/productsStatus/productStatus";
import WizardApp from "../common/scripts/wizard-app";
import config, { accessToken, getEnv } from "../../config";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const IndexView = (props) => {
    const gumStartApp = new WizardApp(`${window.location.protocol}//${window.location.host}`);
    const [productDetails, setProductDetails] = useState({ isStart: true, status: "", productStatus: "", statusIcon: "", btnIcon: "", msgContent: "", eventChange: () => { } })
    const [isLoading, setLoader] = useState(true);
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const [customerData, setCustomerData] = useState();
    const getCustomerData = async () =>{
        let orgId = sessionStorage.getItem("orgId");
        if(orgId){
            let url = `${config.endpoints.apiEndpoint}/getOrgDetails/${orgId}`;
            let customerData = await axios.get(url, {
                headers: {
                    token: accessToken(),
                    tokensource: "purecloud",
                    env: getEnv(),
                  }
            });
            setCustomerData(customerData);              
        }      
    }
    useEffect(() => {
        setProductDetails({
            isStart: true,
            status: "success", productStatus: "Product Available", statusIcon: "fas fa-check-circle", btnIcon: "fas fa-chevron-right",
            msgContent: "We're ready to set you up. Please click the Start button.",
        });
    },[])

    useEffect(()=>{
        if (customerData != undefined && customerData.data.message == undefined) {
            sessionStorage.setItem('isInstallationChecked', true);
            console.debug('Check for product availability in AF');
            gumStartApp.validateProductAvailability()
                .then((isAvailable) => {
                    if (!isAvailable) {
                        setProductDetails({
                            isStart: true,
                            status: "fail", productStatus: "Product not available", statusIcon: "fas fa-times-circle",
                            msgContent: "We're sorry but your Genesys Cloud org does not have the Outbound List Manager for SFTP app enabled. Please contact your administrator.",
                        })
                    } else {
                        setProductDetails({
                            isStart: true,
                            status: "success", productStatus: "Product Available", statusIcon: "fas fa-check-circle", btnIcon: "fas fa-chevron-right",
                            msgContent: "We're ready to set you up. Please click the Start button.",
                        });
                    }
                    gumStartApp.getUserDetails()
                        .then((user) => {
                            if (user) {
                                setUserName(user?.name)
                                setLoader(false);
                            }
                        });
                   
                }).catch((err) => {
                    setLoader(false)
                    setProductDetails({
                        isStart: true,
                        status: "fail", productStatus: "Product could not be installed", statusIcon: "fas fa-exclamation-circle",
                        msgContent: "Error in installation.",
                    })
                    console.error('Error in index view: ' + err)
                })
            //Promise.resolve();
        } else if (customerData != undefined && customerData.data.message != undefined){
            navigate('/main');
        }
    }, [customerData])

    useEffect(() => {
        
        gumStartApp.start()
            .then(() => {
                console.debug('Check if the product is installed either via PS/AF');
                getCustomerData();              
            }).catch((err) => {
                setLoader(false)
                setProductDetails({
                    isStart: true,
                    status: "fail", productStatus: "Product could not be installed", statusIcon: "fas fa-exclamation-circle",
                    msgContent: "Error in installation.",
                })
                console.error('Error in index view: ' + err)
            })
    }, []);

    return (
        <>
         {(isLoading == true) && <Loader></Loader>}
        {isLoading == false  && <ParentDiv>
            <div id="background"></div>
            <Header tail="Start"></Header>
            <WizardContent>
                    <WelcomeGreet userName={userName} tabName="start"></WelcomeGreet>
                </WizardContent>
           
            {(isLoading == false && <>
                <ProgressBar tabName="start"></ProgressBar>
                <MainDiv>
                    <ProductStatus {...productDetails} eventChange={() => navigate('/install')}></ProductStatus>
                </MainDiv>   
            </>)}
                
        </ParentDiv>}
        </>
        
       
    )
}

export default IndexView;