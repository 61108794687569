import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Alert,
  AlertColor,
  Backdrop,
  CircularProgress,
  Paper,
  Snackbar,
  TextField,
} from "@mui/material";
import { useState, useEffect, ReactNode, ChangeEvent } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  getServerListService,
  saveServerDetails,
} from "../../services/purecloud";
import { ApiResponse, IServerDetail, IUser } from "../../models";
import { getStorageItem } from "../../services/applicationStorage";

const AddServer = () => {
  const [rows, setRows] = useState<Array<string>>([] as Array<string>);
  const [isFormDataLoading, setFormDataLoading] = useState<boolean>(false);
  const [getSnackDetails, setSnackDetails] = useState({
    open: false,
    message: (<></>) as ReactNode,
    severity: "success",
  });
  const [getNewServerName, setNewServerName] = useState("");

  //This hooks is used to load initial data
  useEffect(() => {
    (async () => {
      try {
        setFormDataLoading(true);
        const apiResponse: ApiResponse<IServerDetail> =
          await getServerListService();
        setRows(apiResponse?.data?.serverList || []);
        setFormDataLoading(false);
      } catch (err) {
        console.error(err);
        setRows([]);
        setFormDataLoading(false);
        setSnackDetails({
          open: true,
          message: <>Please try again</>,
          severity: "error",
        });
      }
    })();
  }, []);

  /**
   * This method is called when the form is submitted
   * @param data form data
   */
  const addServer = async () => {
    if (!getNewServerName) {
      return;
    }
    const configuration = {
      orgId: getStorageItem("organization-id", true, sessionStorage),
      serverList: [...rows, getNewServerName],
    };
    try {
      setFormDataLoading(true);
      await saveServerDetails(configuration);
      setSnackDetails({
        open: true,
        message: <>Server Details Updated Successfully</>,
        severity: "success",
      });
      setRows(configuration.serverList);
      setNewServerName("");
      setFormDataLoading(false);
    } catch (err) {
      console.log("setting form :", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };

  /**
   * This method is used to delete server individual
   * @param serverName name of the server to be deleted
   * @returns void
   */
  const handleIndividualDeleteClick = (serverName) => async () => {
    const updatedServerList = rows.filter((v) => v !== serverName);
    const configuration = {
      orgId: getStorageItem("organization-id", true, sessionStorage),
      serverList: updatedServerList,
    };
    try {
      setFormDataLoading(true);
      await saveServerDetails(configuration);
      setSnackDetails({
        open: true,
        message: <>Server Details Updated Successfully</>,
        severity: "success",
      });

      setRows(updatedServerList);
      setFormDataLoading(false);
    } catch (err) {
      console.log("handleIndividualDeleteClick failed :", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };

  const defaultWidthColumns = 50;
  const columns = [
    {
      field: "serverName",
      headerName: "Server",
      minWidth: defaultWidthColumns,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      getActions: ({ row: { serverName } }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            title="Delete"
            onClick={handleIndividualDeleteClick(serverName)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }} className="jobs-view">
      <div className="m-2 text">Servers</div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFormDataLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper
        elevation={2}
        sx={{
          margin: "10px 5px 5px 5px",
          padding: "10px",
        }}
      >
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <span className="px-1 d-flex align-items-center text">
              Add Server
            </span>
          </div>
          <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 mt-2 mb-2">
            <TextField
              label="Server Name"
              onChange={(val: ChangeEvent<HTMLInputElement>) =>
                setNewServerName(val.target.value.trim())
              }
              value={getNewServerName}
              variant="outlined"
              size="small"
              sx={{ width: "100%", padding: "0px 5px" }}
            />
          </div>
          <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 mt-2 mb-2 d-flex justify-content-end last-row-button">
            <Button
              variant="contained"
              size="medium"
              sx={{
                color: "white",
                backgroundColor: "#1b2c48",
                marginRight: "5px",
              }}
              type="submit"
              onClick={() => {
                addServer();
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </Paper>

      <Box
        sx={{
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFormDataLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DataGrid
          rows={rows.map((server, index) => ({
            id: index + 1,
            serverName: server,
          }))}
          columns={columns}
          initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
        />
      </Box>
      {getSnackDetails.open && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={getSnackDetails.open}
          autoHideDuration={5000}
          onClose={(_event, reason?: string) => {
            if (reason === "clickaway") {
              return;
            }
            setSnackDetails({ open: false, message: <></>, severity: "error" });
          }}
        >
          <Alert
            severity={getSnackDetails.severity as AlertColor}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {getSnackDetails.message as ReactNode}
          </Alert>
        </Snackbar>
      )}
    </Paper>
  );
};

export default AddServer;
