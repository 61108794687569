import WarningRounded from '@mui/icons-material/WarningAmberRounded';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"

const ConfirmDailog = (props) => {
    return (
        <Dialog
            open={props.open}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            className='alert-dailog'
        >
            <DialogTitle className='confirm-title'>
                <WarningRounded />  {props.title}
            </DialogTitle>
            <DialogContent>
                <div className='deleteMessage'>
                    {props.subject}
                </div>
            </DialogContent>
            <DialogActions >
                <Button onClick={props.confirm}>Ok</Button>
                <Button onClick={props.handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDailog;