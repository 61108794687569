import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Fab from "@mui/material/Fab";

const ProtectedHelp = () => {
  /**
   * This method is used to download the PDF document
   */
  const handleDownload = () => {
    const filePath = "/assets/OLM For SFTP.pdf";

    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", "OLM For SFTP.pdf");
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <>
      <p style={{ margin: "5px 0px" }}>
        Please click here to download the help guide
        <Fab
          sx={{ padding: "2px", margin: "2px" }}
          size="small"
          onClick={handleDownload}
          color="primary"
          aria-label="add"
        >
          <FileDownloadIcon />
        </Fab>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 20%",
        }}
      >
        {Array.from(Array(19).keys()).map((index) => (
          <img
            key={index}
            src={"../assets/sftp/" + (index + 1) + "-min.jpg"}
            alt={`Image ${index + 1}`}
            style={{
              marginBottom: "10px",
              border: "1px solid #ccc",
              padding: "5px",
            }}
          />
        ))}
      </div>
    </>
  );
};

export default ProtectedHelp;
