import { useState, useEffect } from "react";
import { Button, ButtonGroup } from "@mui/material";
import "./schedule.css";

const DayListView = (props) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [repeatEvery, setRepeatEvery] = useState(1);

  useEffect(() => {
    setRepeatEvery(+props.repeatEvery);
      setSelectedDays([]);
  }, [props.repeatEvery]);

  const handleSelectDays = (day) => {
    if (repeatEvery === 1) {
      const updatedDays = [...selectedDays];
      const index = updatedDays.indexOf(day);
      if (index !== -1) {
        updatedDays.splice(index, 1);
      } else {
        updatedDays.push(day);
      }
      setSelectedDays(updatedDays);
      props.selectedDays(updatedDays.toString().toUpperCase());
    } else {
      setSelectedDays([day]);
      props.selectedDays(day);
    }
  };

  const renderButton = (day) => {
    const isSelected = selectedDays.includes(day);
    return (
      <Button
        key={day}
        className={`capitalize`}
        sx={{
          backgroundColor: isSelected ? "#3f51b5" : "white",
          color: isSelected ? "white" : "black",
        }}
        onClick={() => handleSelectDays(day)}
      >
        {day}
      </Button>
    );
  };

  return (
    <div className="dayTypes">
      <ButtonGroup variant="contained">
        {["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].map((day) =>
          renderButton(day)
        )}
      </ButtonGroup>
    </div>
  );
};

export default DayListView;
