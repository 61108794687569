import React from "react";
import styled from 'styled-components';
// import Help from "@mui/icons-material/Help"

const HeaderDiv = styled.div`
    margin: 0;
    font-size: 14px;
    padding: 5px 0px 2px 10px;
    background-color: #FFF;
    border-bottom: 2px solid #DDDDDD;
    display: flex;
`;

const Font = styled.p`
    margin: 12px;
`;

const Header = (props) => {
    
    return (
        <HeaderDiv>
            <Font>Outbound List Manager for SFTP /  {props.tail}</Font>
            {/* <Help className="helpIcon" onClick={() => { window.open("/help", '_blank') }} /> */}
        </HeaderDiv>
    )
};

export default Header;