import Fab from "@mui/material/Fab";
import { useState } from "react";
interface Props {
  selectedDates: (dates: string) => void;
}
const DaysSelector = ({ selectedDates }: Props) => {
  const [selectedDays, setSelectedDays] = useState<
    Array<string | number | "L">
  >([]);

  const toggleDaySelection = (day) => {
    let selectedDate: Array<string | number | "L"> = [];
    if (day === "L") {
      // If 'L' is already selected, deselect it
      if (selectedDays.includes("L")) {
        selectedDate = [];
      } else {
        // Select 'L' and deselect all other days
        selectedDate = ["L"];
      }
    } else {
      // If any day other than 'L' is selected, deselect 'L'
      const newSelectedDays = selectedDays.filter((d) => d !== "L");
      if (newSelectedDays.includes(day)) {
        // If the day is already selected, deselect it

        selectedDate = newSelectedDays.filter((d) => d !== day);
      } else {
        // Add the selected day to the list
        selectedDate = [...newSelectedDays, day];
      }
    }
    setSelectedDays(selectedDate);
    selectedDates(selectedDate.join(","));
  };

  return (
    <div>
      <p>* On the following Days of the Month</p>
      {Array.from(Array(31).keys()).map((i) => {
        const day = i + 1;
        return (
          <Fab
            size="small"
            sx={{
              backgroundColor: selectedDays.includes(day) ? "#1976d2" : "white",
              margin: "2px",
              color: selectedDays.includes(day) ? "white" : "black",
              border: `2px solid ${
                selectedDays.includes(day) ? "#1976d2" : "#c4c4c4"
              }`,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: selectedDays.includes(day)
                  ? "#1976d2"
                  : "white",
                color: selectedDays.includes(day) ? "white" : "black",
                boxShadow: "none", // Remove hover effect
                border: `2px solid ${
                  selectedDays.includes(day) ? "#1976d2" : "#c4c4c4"
                }`,
              },
            }}
            disableRipple={true}
            disableFocusRipple={true}
            onClick={() => toggleDaySelection(day)}
          >
            {day}
          </Fab>
        );
      })}
      <Fab
        size="small"
        sx={{
          backgroundColor: selectedDays.includes("L") ? "#1976d2" : "white",
          margin: "2px",
          color: selectedDays.includes("L") ? "white" : "black",
          border: `2px solid ${
            selectedDays.includes("L") ? "#1976d2" : "#c4c4c4"
          }`,
          boxShadow: "none",
          "&:hover": {
            backgroundColor: selectedDays.includes("L") ? "#1976d2" : "white",
            color: selectedDays.includes("L") ? "white" : "black",
            boxShadow: "none", // Remove hover effect
            border: `2px solid ${
              selectedDays.includes("L") ? "#1976d2" : "#c4c4c4"
            }`,
          },
        }}
        disableRipple={true}
        disableFocusRipple={true}
        onClick={() => toggleDaySelection("L")}
      >
        L
      </Fab>
    </div>
  );
};

export default DaysSelector;
