import Paper from "@mui/material/Paper";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./Configuration.scss";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
const { awsCronExpressionValidator } = require("aws-cron-expression-validator");
import Button from "@mui/material/Button";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { useLocation, useNavigate } from "react-router-dom";
import momentTimeZone from "moment-timezone";
import {
  AppendType,
  AppendTypeForDNC,
  AppendTypeList,
  AppendTypeListForDNC,
  AuthenticationType,
  EmailType,
  FileFormatOperationTypeList,
  PhoneType,
  RoutesName,
  RulesType,
  initialConfigurationValue,
} from "../../common/constants";
import {
  FileSpecificationTemplate,
  IConfiguration,
  IDomainListItem,
  IScheduledTask,
  IUser,
  ImportTemplate,
} from "../../models";
import SchedulerDialog from "./schedulerDialog";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import cronstrue from "cronstrue";
import { getStorageItem } from "../../services/applicationStorage";
import {
  saveSftpConfiguration,
  getAllContactList,
  getAllDNCList,
  getUserMe,
  testConnection,
  getServerListService,
  updateSftpConfiguration,
  getExitingSftpConfiguration,
  getAllDomainList,
  getSSHPublicKey,
  getAllFileSpecificationTemplates,
  getAllImportTemplates,
  getSettingsConfiguration,
  getAllCampaign,
} from "../../services/purecloud";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Checkbox } from "@mui/material";

const ToggleButton = styled(MuiToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#1b2c48",
  },
  fontWeight: 700,
  color: "black",
  textAlign: "center",
});

interface ExcelRow {
  [key: string]: string;
}

/**
 * Configuration Component
 */
const Configuration = () => {
  const PhoneTypes = [
    {
      displayValue: "Cell",
      value: PhoneType.cell,
    },
    {
      displayValue: "Day Time",
      value: PhoneType.daytime,
    },
    {
      displayValue: "Evening",
      value: PhoneType.evening,
    },
    {
      displayValue: "Home",
      value: PhoneType.home,
    },
    {
      displayValue: "Work",
      value: PhoneType.work,
    },
  ];
  const EmailTypes = [
    {
      displayValue: "Personal",
      value: EmailType.personal,
    },
    {
      displayValue: "Work",
      value: EmailType.work,
    },
  ];
  const [contactListItems, setContactListItems] = useState<
    Array<{ id; name; phoneColumns }>
  >([]);

  const [dncListItems, setDncListItems] = useState<
    Array<{ id; name; phoneColumns }>
  >([]);

  const [exportContactListItems, setExportContactListItems] = useState<
    Array<{ id; name; phoneColumns }>
  >([]);

  const [exportDNCItems, setExportDNCItems] = useState<
    Array<{ id; name; phoneColumns }>
  >([]);

  const [campaignList, setCampaignList] = useState<
    Array<{ id; name; contactList: { id; name }, dncLists : Array<{id, name, selfUri}> }>
  >([]);
  const [fileSpecTemplateList, setFileSpecTemplateList] = useState<
    Array<FileSpecificationTemplate>
  >([]);
  const [importTemplateList, setImportTemplateList] = useState<
    Array<ImportTemplate>
  >([]);
  const [getPublicKeyDetails, setPublicKeyDetails] = useState<{
    publicKey: string;
    fingerprint: string;
  }>({ publicKey: "", fingerprint: "" });
  const [getServerList, setServerList] = useState<Array<string>>([]);
  const [getDisableSchedule, setDisableSchedule] = useState<boolean>(false); // change to false
  const [isSFTPCredsValid, setIsSFTPCredsValid] = useState<boolean>(false); // change to false
  const [getUserDetails, setUserDetails] = useState<IUser>({} as IUser);
  const [getSnackDetails, setSnackDetails] = useState({
    open: false,
    message: (<></>) as ReactNode,
    severity: "success",
  });
  const [selectedAdditionalAttribute, setSelectedAdditionalAttribute] =
    useState<string>("");
    
  const [
    selectedAdditionalAttributeExport,
    setSelectedAdditionalAttributeExport,
  ] = useState<{
    zipFiles?: boolean;
    encryptFiles?: boolean;
    deleteFiles: boolean;
    clearAll: boolean;
    isMoveFiles: boolean;
    traceConnection: boolean;
  }>({
    deleteFiles: false,
    clearAll: false,
    isMoveFiles: false,
    zipFiles: false,
    encryptFiles: true,
    traceConnection: true,
  });
  let canWeEncryptExportOperation = true;
  const [isSchedulerDialogOpen, setSchedulerDialogOpen] =
    useState<boolean>(false);
  const [isFormDataLoading, setFormDataLoading] = useState<boolean>(false);
  const [domainList, setDomainList] = useState<IDomainListItem[]>([]);
  const [excelData, setExcelData] = useState<ExcelRow[]>([]);
  const [excelHeaderCols, setExcelHeaderCols] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    setValue,
    setError,
    watch,
  } = useForm({ defaultValues: initialConfigurationValue });

  const {
    fields: phoneFields,
    append: phoneAppend,
    remove: phoneRemove,
  } = useFieldArray({
    control,
    name: "rules.importFile.contactListConfiguration.phoneColumns",
  });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(location.search);
      let response;
      try {
        setFormDataLoading(true);
        //Since the date property is not setting properly above, so manual assigned it
        setValue("scheduleDetails.date", moment());
        if (queryParams.has("jobId")) {
          response = await getExitingSftpConfiguration(
            queryParams.get("jobId")
          );
          setDisableSchedule(true); // NOT allow change of schedule/non schedule after a job is saved (disable the schedule toggle for edit)
          if (response) {
            response = JSON.parse(JSON.stringify(response.data));
            response.scheduleDetails.date = moment(
              new Date(response.scheduleDetails.date)
            );
            if (response.scheduleDetails.cron) {
              const text: string = cronstrue.toString(
                response.scheduleDetails.cron as string,
                {
                  verbose: true,
                  monthStartIndexZero: true,
                }
              );
              setValue("scheduleDetails.cronText", text);
            }
            if (response.serverDetails.additionalAttributes.deleteFiles) {
              setSelectedAdditionalAttribute("deleteFiles");
            } else if (response.serverDetails.additionalAttributes.clearAll) {
              setSelectedAdditionalAttribute("clearAll");
            } else if (
              response.serverDetails.additionalAttributes.isMoveFiles
            ) {
              setSelectedAdditionalAttribute("isMoveFiles");
            }

            setSelectedAdditionalAttributeExport({
              deleteFiles: false,
              clearAll: false,
              isMoveFiles: false,
              traceConnection: true,
              encryptFiles:
                response.serverDetails.additionalAttributes.encryptFiles,
              zipFiles: response.serverDetails.additionalAttributes.zipFiles,
            });

            const updatedRules =
              response.rules.ruleType === RulesType.importFile
                ? {
                    ...response.rules,
                    [RulesType.exportContactList]:
                      initialConfigurationValue.rules.exportContactList,
                  }
                : {
                    ...response.rules,
                    [RulesType.importFile]:
                      initialConfigurationValue.rules.importFile,
                  };
            if (response.rules.ruleType === RulesType.importFile) {
              if (
                response.rules.importFile.appendType ===
                  AppendType.appendonly ||
                response.rules.importFile.appendType ===
                  AppendType.flushandappend ||
                response.rules.importFile.importTemplateId
              ) {
                response.rules.importFile.contactListConfiguration = {
                  name: "",
                  division: {
                    id: response.rules.importFile.importTemplateId
                      ? response.rules.importFile.division
                      : "",
                  },
                  useSpecificationFile: response.rules.importFile
                    .fileSpecificationTemplateId
                    ? true
                    : false,
                  useImportTemplate: response.rules.importFile.importTemplateId
                    ? true
                    : false,
                  trimWhitespace: true,
                  columnNames: [],
                  phoneColumns: [
                    { columnName: "", type: "", callableTimeColumn: "" },
                  ],
                };
              }
              setExcelHeaderCols(
                response.rules.importFile.contactListConfiguration.columnNames
              );
            }
            setValue("rules", { ...response.rules, ...updatedRules });
            setValue(
              "rules.importFile.contactListConfiguration.phoneColumns" as keyof IConfiguration,
              (response.rules?.importFile?.contactListConfiguration
                ?.phoneColumns as any) || [
                { columnName: "", type: "", callableTimeColumn: "" },
              ]
            );

            Object.keys(response).forEach((field) => {
              if (response[field]) {
                setValue(field as keyof IConfiguration, response[field]);
              }
            });

            if (response.rules.ruleType === RulesType.exportContactList) {
              setValue(
                "rules.importFile.appendType" as keyof IConfiguration,
                AppendType.newlistsonly
              );
            }
            //Since the date property is not setting properly above, so manual assigned it
            setValue("scheduleDetails.date", response.scheduleDetails.date);
            if(response.rules.ruleType === RulesType.importDNCList) {
              const rulesToCopy = response.rules.importFile;
              delete response.rules.importFile;
              setValue("rules", {
                ...response.rules,
                [RulesType.importDNCList]: rulesToCopy
              });
              if(rulesToCopy.appendType === AppendTypeForDNC.newlistsonly) {
                setExcelHeaderCols(
                  rulesToCopy.contactListConfiguration.customExclusionColumns
                );
              }
            } else if(response.rules.ruleType === RulesType.exportDNCList) {
              setValue(
                "rules.importDNCList.appendType" as keyof IConfiguration,
                AppendType.newlistsonly
              );
              const rulesToCopy = response.rules.exportContactList;
              delete response.rules.exportContactList;
              setValue("rules", {
                ...response.rules,
                [RulesType.exportDNCList]: rulesToCopy
              });
            }
          }
        }

        const [
          contactList,
          dncList,
          allServerList,
          userDetails,
          allDomainList,
          publicKeyData,
          fileSpecs,
          importTemplates,
          sftpSettings,
          campaigns,
        ] = await Promise.all([
          getAllContactList(),
          getAllDNCList(),
          getServerListService().then((server) =>
            ((server?.data?.serverList as Array<string>) || []).sort((a, b) =>
              a.localeCompare(b)
            )
          ),
          getUserMe(),
          getAllDomainList(),
          getSSHPublicKey(),
          getAllFileSpecificationTemplates(),
          getAllImportTemplates(),
          getSettingsConfiguration(),
          getAllCampaign(),
        ]);
        setContactListItems(contactList);
        setDncListItems(dncList);
        setExportContactListItems(contactList);
        setExportDNCItems(dncList);
        setServerList(allServerList || []);
        setUserDetails(userDetails || {});
        setDomainList(allDomainList as IDomainListItem[]);
        setPublicKeyDetails(publicKeyData.data);
        setFileSpecTemplateList(
          fileSpecs.sort((a, b) => a.name.localeCompare(b.name)) || []
        );
        setImportTemplateList(
          importTemplates.sort((a, b) => a.name.localeCompare(b.name)) || []
        );
        const keys = sftpSettings?.data?.sftpSetting || [];
        canWeEncryptExportOperation = keys.some(
          (key) =>
            key.expDate === "Never" || moment(key.expDate).isAfter(moment())
        );
        if (!canWeEncryptExportOperation) {
          setSelectedAdditionalAttributeExport((prev) => ({
            ...prev,
            encryptFiles: false,
          }));
        }
        setCampaignList(campaigns);
        if (response.rules?.exportContactList?.campaignId) {
          const campaign = campaigns.find(
            (campaign) =>
              campaign.id === response.rules.exportContactList.campaignId
          );
          const filteredContactList = contactList.find(
            (contact) => contact.id === campaign.contactList.id
          );
          if (filteredContactList) {
            setExportContactListItems([filteredContactList]);
          } else {
            setExportContactListItems([]);
          }
        } else if (response.rules?.exportDNCList?.campaignId) {
          const campaign = campaigns.find(
            (campaign) =>
              campaign.id === response.rules.exportDNCList.campaignId
          );
          const campaignDncLists = campaign.dncLists.map( item => item.id );
          const filteredDncList = dncList.find(
            (list) => campaignDncLists.includes(list.id)
          );
          if (filteredDncList) {
            setExportDNCItems([filteredDncList]);
          } else {
            setExportDNCItems([]);
          }
        }

        setFormDataLoading(false);
      } catch (error) {
        setFormDataLoading(false);
        console.error("Error fetching data:", error);
      }
    })();
  }, []);

  //This hook is used to handle alert for un-saved UI changes
  useEffect(() => {
    //Refresh logic
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = ""; // Required for some browsers
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Clean up the event listener
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  /**
   * This method is used handle the file upload event
   * @param e file
   * @returns void
   */
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      // Check if the file type is Excel or CSV
      if (!file.name.match(/\.(xlsx|xls|csv|txt|dat|dsv)$/)) {
        alert("Please upload CSV or Excel file");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }

      // Check if the file size is greater than 1MB
      if (file.size > 1024 * 1024) {
        alert("File size shouldn't be greater than 1MB");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }

      const reader = new FileReader();
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      reader.onload = (event) => {
        let data = new Uint8Array(event.target?.result as ArrayBuffer);
        if (
          (watch(
            "rules.importFile.contactListConfiguration.useSpecificationFile"
          ) &&
            fileExtension?.toLocaleLowerCase() === "csv") ||
          fileExtension?.toLocaleLowerCase() === "dsv" ||
          fileExtension?.toLocaleLowerCase() === "dat" ||
          fileExtension?.toLocaleLowerCase() === "txt"
        ) {
          const specId =
            watch("rules.importFile.fileSpecificationTemplateId") || "";
          if (specId) {
            const spec = fileSpecTemplateList.find((v) => v.id === specId);

            const delimitedObj = {
              Comma: ",",
              Colon: ":",
              Semicolon: ";",
              Tab: "  ",
              Pipe: "\\|",
              Custom: spec?.delimiterValue || ",",
            };

            // Convert Uint8Array to string
            const dataString = new TextDecoder().decode(data);

            let delimiter =
              spec?.format === "Delimited" ? delimitedObj[spec.delimiter] : ",";
            // Replace semicolons with commas
            const modifiedDataString = dataString.replace(
              new RegExp(delimiter, "g"),
              ","
            );

            // Convert back to Uint8Array
            data = new TextEncoder().encode(modifiedDataString);
          }
        }

        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet) as ExcelRow[];
        const headerCols = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        })[0] as string[];
        const seenHeader = new Set();
        for (const header of headerCols) {
          if (seenHeader.has(header)) {
            alert("This file includes duplicate header values.");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            return;
          }
          seenHeader.add(header);
        }
        if (jsonData.length > 0) {
          const headers = [...Object.keys(jsonData[0])];
          for (const header of headers) {
            if (header.startsWith("__EMPTY")) {
              alert("This file has blank header columns.");
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
              return;
            }
          }
        } else {
          alert(
            "The file must have a header row and at least one valid row of data."
          );
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          return;
        }
        setExcelHeaderCols(headerCols);
        const excelData = jsonData.slice(0, 2).map((data) => {
          return headerCols.reduce((obj, headerName) => {
            obj[headerName] = data[headerName] ?? "";
            return obj;
          }, {});
        });
        setExcelData(excelData);
        setValue(
          "rules.importFile.contactListConfiguration.phoneColumns" as keyof IConfiguration,
          [{ columnName: "", type: "", callableTimeColumn: "" }] as any
        );
      };

      reader.readAsArrayBuffer(file);
    }
  };
  /**
   * This method is triggerd on selecting radio and it update the selected checkbox value in array
   * @param name name of item in that list
   * @returns void
   */
  const handleAdditionalAttributesRadioChange = (event) => {
    let value = event.target.value;
    if (!event.target.checked) {
      value = "clearAll";
    }
    setSelectedAdditionalAttribute(value);
    const obj = {
      deleteFiles: value === "deleteFiles",
      clearAll: value === "clearAll",
      isMoveFiles: value === "isMoveFiles",
      zipFiles: false,
      encryptFiles: false,
      traceConnection: true,
    };
    if (obj.isMoveFiles === true) {
      obj["moveFiles"] = {
        folderName: watch(
          "serverDetails.additionalAttributes.moveFiles.folderName"
        ),
      };
    } else {
      obj["moveFiles"] = {};
    }
    setValue("serverDetails.additionalAttributes", obj);
  };

  /**
   * This method is used to disable schedule field
   * @param type schedule Type
   */
  const disableScheduleField = (type: "cron" | "manual") => {
    return !watch("schedule") || watch("scheduleDetails.scheduleType") === type;
  };
  /**
   * This method is called when the form is submitted
   * @param data form data
   */
  const handleConfigurationFormSubmit = async (data) => {
    const configuration = {
      orgId: getStorageItem("organization-id", true, sessionStorage),
      orgName: getStorageItem("organization-name", true, sessionStorage),
      userId: getUserDetails.id,
      user: getUserDetails.name,
      ...data,
    };
    configuration.scheduleDetails.isScheduleEventRuleEnabled = true;
    if (!configuration.schedule) {
      setValue("scheduleDetails.cronText", "");
      setValue("scheduleDetails.cron", "");
    } else if (!configuration.scheduleDetails.cron) {
      setCronExpression();
      configuration.scheduleDetails.cron = watch("scheduleDetails.cron");
    }

    if (configuration.rules.ruleType === RulesType.importFile) {
      if (
        configuration.rules.importFile?.appendType === AppendType.newlistsonly
      ) {
        configuration.rules.importFile.contactListConfiguration = {
          attemptLimits: null,
          automaticTimeZoneMapping: false,
          emailColumns: [],
          previewModeAcceptedValues: [],
          previewModeColumnName: "",
          zipCodeColumnName: null,
          ...configuration.rules.importFile.contactListConfiguration,
          columnNames: excelHeaderCols,
        };

        if (
          !watch(
            "rules.importFile.contactListConfiguration.useSpecificationFile"
          )
        ) {
          delete configuration.rules.importFile.fileSpecificationTemplateId;
        }

        if (
          !watch("rules.importFile.contactListConfiguration.useImportTemplate")
        ) {
          delete configuration.rules.importFile.importTemplateId;
          delete configuration.rules.importFile.division;
        } else {
          configuration.rules.importFile.division =
            configuration.rules.importFile.contactListConfiguration.division.id;
          delete configuration.rules.importFile.contactListConfiguration;
        }
      } else if (
        configuration.rules.importFile?.appendType === AppendType.appendonly ||
        configuration.rules.importFile?.appendType === AppendType.flushandappend
      ) {
        delete configuration.rules.importFile.contactListConfiguration;
        delete configuration.rules.importFile.fileSpecificationTemplateId;
        delete configuration.rules.importFile.importTemplateId;
      }
      delete configuration.rules.exportContactList;
      delete configuration.rules.importDNCList;
      delete configuration.rules.exportDNCList;
    } else if(configuration.rules.ruleType === RulesType.exportContactList) {
      configuration.serverDetails.additionalAttributes =
        selectedAdditionalAttributeExport;
      delete configuration.rules.importFile;
      delete configuration.rules.importDNCList;
      delete configuration.rules.exportDNCList;
    } else if(configuration.rules.ruleType === RulesType.importDNCList) {
      delete configuration.rules.importFile;
      delete configuration.rules.exportDNCList;
      delete configuration.rules.exportContactList;
      if (
        configuration.rules.importDNCList?.appendType === AppendTypeForDNC.newlistsonly
      ) {
        configuration.rules.importFile = {
          "appendType": configuration.rules.importDNCList.appendType,
          "contactListConfiguration": {
            "contactMethod": "any",
            "customExclusionColumn": configuration.rules.importDNCList.contactListConfiguration.customExclusionColumn,
            "customExclusionColumns": configuration.rules.importDNCList.contactListConfiguration.customExclusionColumns,
            "division": {
              "id": configuration.rules.importDNCList.contactListConfiguration.division.id
            },
            "dncSourceType": "rds_custom",
            "expirationDateTimeColumn": configuration.rules.importDNCList.contactListConfiguration.expirationDateTimeColumn
          },
          "fileFormatName": configuration.rules.importDNCList.fileFormatName,
          "fileFormatOperation": configuration.rules.importDNCList.fileFormatOperation
        };
      } else if(configuration.rules.importDNCList?.appendType === AppendTypeForDNC.appendonly) {
        configuration.rules.importFile = {
          "appendType": configuration.rules.importDNCList.appendType,
          "contactListId": configuration.rules.importDNCList.contactListId,
          "fileFormatName": configuration.rules.importDNCList.fileFormatName,
          "fileFormatOperation": configuration.rules.importDNCList.fileFormatOperation,
          "contactListConfiguration": {
            "customExclusionColumns": configuration.rules.importDNCList.contactListConfiguration.customExclusionColumns,
            "expirationDateTimeColumn": configuration.rules.importDNCList.contactListConfiguration.expirationDateTimeColumn
          }
        };
      }
      delete configuration.rules.importDNCList;
    } else if(configuration.rules.ruleType === RulesType.exportDNCList) {
      configuration.serverDetails.additionalAttributes =
        selectedAdditionalAttributeExport;
      delete configuration.rules.importFile;
      delete configuration.rules.importDNCList;
      delete configuration.rules.exportContactList;
      configuration.rules.exportContactList = {
        "campaignId": configuration.rules.exportDNCList.campaignId,
        "contactList": configuration.rules.exportDNCList.contactList,
        "contactListId": configuration.rules.exportDNCList.contactListId,
        "outputFileFormat": ""
      };
      delete configuration.rules.exportDNCList;
    }
    try {
      setFormDataLoading(true);

      const queryParams = new URLSearchParams(location.search);
      const response = queryParams.has("jobId")
        ? await updateSftpConfiguration(configuration)
        : await saveSftpConfiguration(configuration);

      const snackDetails = {
        open: true,
        message: <>{response.message}</>,
        severity: "success",
      };

      setSnackDetails(snackDetails);
      navigate(`../${RoutesName.jobsview}`);
      setFormDataLoading(false);
    } catch (err) {
      console.error("Configuration Failed : ", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };

  /**
   * This method is used hid or display phone col
   * @returns true or false
   */
  const showPhoneMappingCols = () => {
    return phoneFields.length && excelHeaderCols.length ? true : false;
  };

  /**
   * This method is used hid or display dnc col
   * @returns true or false
   */
  const showDNCCols = () => {
    return excelHeaderCols.length ? true : false;
  };

  /**
   *This method is used to disable the form if it invalid
   */
  const disableForm = () => {
    if (
      watch("rules.ruleType") === RulesType.importFile &&
      watch("rules.importFile.appendType") === AppendType.newlistsonly &&
      watch("rules.importFile.contactListConfiguration.useImportTemplate") ===
        false
    ) {
      return !isValid || !showPhoneMappingCols();
    }
    return !isValid;
  };

  /**
   * This method is used to test the sftp creds
   */
  const testConnectionHandler = async () => {
    let body: any = {
      host: watch("serverDetails.fileServer") || "",
      port: 22,
      username: watch("serverDetails.userName") || "",
      type: watch("serverDetails.authenticationType") || "",
    };

    if (
      watch("serverDetails.authenticationType") ===
        AuthenticationType.password ||
      watch("serverDetails.authenticationType") ===
        AuthenticationType.sshandpassword
    ) {
      body = {
        host: watch("serverDetails.fileServer") || "",
        port: 22,
        username: watch("serverDetails.userName") || "",
        password: watch("serverDetails.password") || "",
        type: watch("serverDetails.authenticationType") || "",
      };
      if (body.host === "" || body.username === "" || body["password"] === "") {
        setSnackDetails({
          open: true,
          message: <>Enter Creds</>,
          severity: "error",
        });
        return;
      }
    } else {
      if (body.host === "" || body.username === "") {
        setSnackDetails({
          open: true,
          message: <>Enter Creds</>,
          severity: "error",
        });
        return;
      }
    }
    setFormDataLoading(true);
    try {
      const sftpConnectionValidity = await testConnection(body);
      setIsSFTPCredsValid(
        sftpConnectionValidity.message === "Connection successful!"
      );
      setSnackDetails({
        open: true,
        message: (
          <>
            {sftpConnectionValidity.message === "Connection successful!"
              ? "Test Connection is Successful"
              : "Invalid Credentials"}
          </>
        ),
        severity:
          sftpConnectionValidity.message === "Connection successful!"
            ? "success"
            : "error",
      });
      setFormDataLoading(false);
    } catch (err) {
      setSnackDetails({
        open: true,
        message: <>Invalid Credentials</>,
        severity: "error",
      });
      setIsSFTPCredsValid(false);
      setFormDataLoading(false);
    }
  };

  /**
   * This method is used to set the cron expression on change of date
   */
  const setCronExpression = () => {
    const date = moment.utc(watch("scheduleDetails.date") || moment());
    const day = parseInt(date.format("DD"), 10); // Extract day as number
    const month = parseInt(date.format("MM"), 10); // Extract month as number
    const minutes = parseInt(date.format("mm"), 10); // Extract minutes as number
    const hours = parseInt(date.format("HH"), 10); // Extract hours as number

    const cronExp = minutes + " " + hours + " " + day + " " + month + " ? *";
    const cronExpForText =
      minutes + " " + hours + " " + day + " " + (month - 1) + " ? *";
    setValue(
      "scheduleDetails.cronText",
      cronstrue.toString(cronExpForText as string, {
        verbose: true,
        monthStartIndexZero: true,
      })
    );
    setValue("scheduleDetails.cron", cronExp);
  };

  /**
   * This method is triggered when save button is select in scheduler dialog and data from dialog box is updated in main form
   * @param data scheduler dialog data like as given in IScheduled interface
   * @returns void
   */
  const handleSchedulerDialogSaveData = (data: IScheduledTask): void => {
    setValue("scheduleDetails.description", data.description || "");
    setValue("scheduleDetails.date", moment(data.startDate));
    //setHumanReadableText(data.cronWordExp as string);
    const text: string = cronstrue.toString(data.cronExpression as string, {
      verbose: true,
      monthStartIndexZero: true,
    });
    setValue("scheduleDetails.cronText", text);
    setValue("scheduleDetails.cron", data.cronExpression as string);
    setSchedulerDialogOpen(false);
  };

  /**
   *This method is used evaluate cron express is less than 5 mins or not
   * @param cronExpression cron expression
   * @returns true if it is less than 5 mins else false
   */
  const isCronExpressionLessThan5Minutes = (cronExpression) => {
    // Split the expression into its fields
    const fields = cronExpression.split(" ");

    // Parse the minutes field
    const minutesField = fields[0];

    // Check if the minutes field allows the expression to run more frequently than every 5 minutes
    if (minutesField === "*") {
      return true; // Runs every minute, which is less than 5 minutes
    }

    // Split the minutes field into its elements
    const minuteElements = minutesField.split(",");

    // Check each element for ranges and steps
    for (const element of minuteElements) {
      if (element.includes("/")) {
        // If the element contains a step, extract the step value and check if it's less than or equal to 5
        const stepValue = parseInt(element.split("/")[1]);
        if (stepValue < 5) {
          return true; // Cron expression runs every 5 minutes or less frequently
        }
      } else if (element.includes("-")) {
        // If the element contains a range, extract the start and end values
        const [start, end] = element.split("-");
        const startValue = parseInt(start);
        const endValue = parseInt(end);
        if (endValue - startValue <= 5) {
          return true; // Cron expression runs every 5 minutes or less frequently
        }
      } else {
        // If the element is a single value, parse it and check if it's less than or equal to 5
        const value = parseInt(element);
        if (value < 5) {
          return true; // Cron expression runs every 5 minutes or less frequently
        }
      }
    }
    return false; // Cron expression runs more frequently than every 5 minutes
  };

  /**
   * This method is used to check whether the cron expression is correct or not and converts into human readable form
   */
  const handleCronExpression = () => {
    try {
      const cronExp = watch("scheduleDetails.cron");
      awsCronExpressionValidator(cronExp);
      const str = cronExp?.toString();
      const text: string = cronstrue.toString(str as string, {
        verbose: true,
        monthStartIndexZero: true,
      });
      if (isCronExpressionLessThan5Minutes(str)) {
        setError("scheduleDetails.cron", {
          type: "manual",
          message: "Invalid CRON Expression.",
        });
        setValue("scheduleDetails.cronText", "Invalid CRON Expression");
        return;
      }
      setValue("scheduleDetails.cronText", text);
    } catch (err) {
      setError("scheduleDetails.cron", {
        type: "manual",
        message: "Invalid CRON Expression.",
      });
      setValue("scheduleDetails.cronText", "Invalid CRON Expression");
    }
  };

  // This method is used to download the public key for ssh
  const handlePublicKeyDownload = () => {
    // Creating a blob with MIME type 'text/plain'
    const file = new Blob([getPublicKeyDetails.publicKey], {
      type: "text/plain",
    });

    // Creating a temporary link to trigger the download
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = "publicKey.pub"; // Setting the file name for the download

    // Triggering the download
    element.click();

    // Optional: releasing the blob URL object after the download to free up resources
    URL.revokeObjectURL(element.href);
  };

  return (
    <Paper className="configuration">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFormDataLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(handleConfigurationFormSubmit)}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <span className="px-1 d-flex align-items-center text">
              <InfoOutlinedIcon /> All fields marked with an asterisk(*) are
              required
            </span>
            <div className="mt-3 mb-2 d-flex">
              <TextField
                label="Automation Name *"
                {...register("automationName", {
                  required: true,
                })}
                value={watch("automationName")}
                variant="outlined"
                size="small"
                sx={{ width: "50%", padding: "0px 5px" }}
                error={!!errors.automationName}
                helperText={errors.automationName?.message}
              />
              <TextField
                label="Automation Description"
                {...register("automationDescription")}
                value={watch("automationDescription")}
                variant="outlined"
                size="small"
                sx={{ width: "50%", padding: "0px 5px" }}
              />
            </div>

            <section className="mt-3 mb-2">
              <div className="m-2 text">
                Schedule
                <Switch
                  size="small"
                  {...register("schedule")}
                  checked={watch("schedule")}
                  disabled={getDisableSchedule}
                  onChange={() => {
                    setValue("schedule", !watch("schedule"));
                    setValue("scheduleDetails.cron", "");
                    setValue("scheduleDetails.cronText", "");
                  }}
                />
              </div>
              <Paper
                elevation={2}
                sx={{
                  margin: "10px 5px 5px 5px",
                  padding: "10px",
                  minHeight: 445,
                }}
              >
                <FormControl component="fieldset" sx={{ width: "100%" }}>
                  <div className="row">
                    {/* Manual Option */}
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                      <Radio
                        checked={
                          watch("scheduleDetails.scheduleType") === "manual"
                        }
                        onChange={() =>
                          setValue("scheduleDetails.scheduleType", "manual")
                        }
                        value="manual"
                        disabled={!watch("schedule")}
                      />
                    </div>
                    {/* Manual Controls */}
                    <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                      <Controller
                        name="scheduleDetails.timeZone"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            disabled={disableScheduleField("cron")}
                            size="small"
                            sx={{ marginTop: "10px", width: "100%" }}
                            options={momentTimeZone.tz.names()}
                            {...field}
                            value={watch("scheduleDetails.timeZone")}
                            onChange={(_event, newValue) => {
                              setValue(
                                "scheduleDetails.timeZone",
                                newValue as string
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Time Zone *" />
                            )}
                          />
                        )}
                      />
                      <div className="mtw-30 d-flex justify-content-between">
                        <Controller
                          control={control}
                          name="scheduleDetails.date"
                          rules={{
                            required: "Schedule Date is required",
                          }}
                          render={({ field }) => (
                            <DateTimePicker
                              {...field}
                              label="Schedule Date"
                              views={[
                                "year",
                                "month",
                                "day",
                                "hours",
                                "minutes",
                                "seconds",
                              ]}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              // minDate={moment()} // Set the minimum date to the current date
                              sx={{ width: "60%" }}
                              disabled={disableScheduleField("cron")}
                              onChange={(date) => {
                                // Update the form value using setValue
                                setValue("scheduleDetails.date", date!);
                                setCronExpression();
                              }}
                            />
                          )}
                        />
                        <div className="m-2 text-500">
                          Recurrence
                          <IconButton
                            disabled={disableScheduleField("cron")}
                            onClick={() => setSchedulerDialogOpen(true)}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                          <SchedulerDialog
                            dailogOpen={isSchedulerDialogOpen}
                            handleClose={() => setSchedulerDialogOpen(false)}
                            schedulerData={{
                              startDate: watch("scheduleDetails.date"),
                              description: watch("scheduleDetails.description"),
                            }}
                            toSaveData={handleSchedulerDialogSaveData}
                            templateName={
                              watch("scheduleDetails.timeZone") || ""
                            }
                            isUpdate={false}
                          />
                        </div>
                      </div>
                      <TextField
                        {...register("scheduleDetails.description")}
                        label="Description"
                        multiline
                        rows={4}
                        value={watch("scheduleDetails.description") || ""}
                        disabled={disableScheduleField("cron")}
                        sx={{ width: "100%", marginTop: "30px" }}
                      />
                    </div>
                    {/* Cron Option */}
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                      <Radio
                        checked={
                          watch("scheduleDetails.scheduleType") === "cron"
                        }
                        onChange={() =>
                          setValue("scheduleDetails.scheduleType", "cron")
                        }
                        value="cron"
                        disabled={!watch("schedule")}
                      />
                    </div>
                    {/* Cron Controls */}
                    <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                      <TextField
                        {...register("scheduleDetails.cron")}
                        label="Cron"
                        size="small"
                        value={watch("scheduleDetails.cron") || ""}
                        disabled={disableScheduleField("manual")}
                        onBlur={handleCronExpression}
                        error={!!errors["scheduleDetails.cron"]}
                        sx={{ width: "100%", marginTop: "10px" }}
                      />
                      <p>Note: cron expression below 5 mins are not allowed</p>
                      <p>{watch("scheduleDetails.cronText")}</p>
                    </div>
                  </div>
                </FormControl>
              </Paper>
            </section>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <span className="px-1 text">File Transfer Options (SFTP)</span>
            <Paper
              elevation={2}
              sx={{
                margin: "10px 5px 5px 5px",
                padding: "10px",
                minHeight: 543,
              }}
            >
              <TextField
                {...register("serverDetails.userName", { required: true })}
                label="User Name *"
                value={watch("serverDetails.userName") || ""}
                variant="outlined"
                size="small"
                error={!!errors?.serverDetails?.userName}
                sx={{ marginTop: "10px", width: "100%" }}
              />
              <section>
                <div className="mtw-30 text">Authenticate Using</div>

                <div className="d-flex justify-content-center">
                  <Controller
                    name="serverDetails.authenticationType"
                    control={control}
                    render={({ field }) => (
                      <ToggleButtonGroup {...field} size="small" exclusive>
                        <ToggleButton value={AuthenticationType.password}>
                          Password
                        </ToggleButton>
                        <ToggleButton value={AuthenticationType.ssh}>
                          SSH Key
                        </ToggleButton>
                        <ToggleButton value={AuthenticationType.sshandpassword}>
                          SSH Key and Password
                        </ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  />
                </div>
                {watch("serverDetails.authenticationType") ===
                  AuthenticationType.password && (
                  <div className="mtw-30 d-flex">
                    <TextField
                      {...register("serverDetails.password", {
                        required: true,
                      })}
                      label="Password *"
                      value={watch("serverDetails.password") || ""}
                      variant="outlined"
                      size="small"
                      type="password"
                      error={!!errors?.serverDetails?.password}
                      sx={{ width: "100%", padding: "0px 5px" }}
                    />
                  </div>
                )}
                {watch("serverDetails.authenticationType") ===
                  AuthenticationType.ssh && (
                  <div className="mtw-30 d-flex justify-content-center">
                    <Button
                      variant="contained"
                      size="medium"
                      sx={{ color: "white", backgroundColor: "#1b2c48" }}
                      onClick={handlePublicKeyDownload}
                    >
                      Download Key
                    </Button>
                  </div>
                )}

                {watch("serverDetails.authenticationType") ===
                  AuthenticationType.sshandpassword && (
                  <div className="mtw-30 d-flex justify-content-between">
                    <TextField
                      {...register("serverDetails.password", {
                        required: true,
                      })}
                      label="Password *"
                      value={watch("serverDetails.password") || ""}
                      variant="outlined"
                      size="small"
                      type="password"
                      error={!!errors?.serverDetails?.password}
                      sx={{ width: "70%", padding: "0px 5px" }}
                    />
                    <Button
                      variant="contained"
                      size="medium"
                      sx={{ color: "white", backgroundColor: "#1b2c48" }}
                      onClick={handlePublicKeyDownload}
                    >
                      Download Key
                    </Button>
                  </div>
                )}
                <div className="mtw-30 d-flex">
                  <Controller
                    name="serverDetails.fileServer"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        disablePortal
                        size="small"
                        sx={{ width: "50%", padding: "0px 5px" }}
                        options={getServerList}
                        {...field}
                        onChange={(_event, newValue) => {
                          setValue("serverDetails.fileServer", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="File Server *" />
                        )}
                      />
                    )}
                  />
                  <TextField
                    {...register("serverDetails.directory", {
                      required: true,
                    })}
                    label="Directory *"
                    value={watch("serverDetails.directory")}
                    variant="outlined"
                    size="small"
                    error={!!errors?.serverDetails?.directory}
                    sx={{ width: "50%", padding: "0px 5px" }}
                  />
                </div>
                {(watch("serverDetails.authenticationType") ===
                  AuthenticationType.sshandpassword ||
                  watch("serverDetails.authenticationType") ===
                    AuthenticationType.ssh) && (
                  // <TextField
                  //   {...register("serverDetails.publicKey", {
                  //     required: true,
                  //   })}
                  //   label="Public Key *"
                  //   value={watch("serverDetails.publicKey")}
                  //   variant="outlined"
                  //   size="small"
                  //   error={!!errors?.serverDetails?.publicKey}
                  //   sx={{
                  //     width: "100%",
                  //     padding: "0px 5px",
                  //     marginTop: "30px",
                  //   }}
                  // />
                  <TextField
                    label="Fingerprint"
                    value={getPublicKeyDetails.fingerprint}
                    variant="outlined"
                    size="small"
                    disabled={true}
                    sx={{
                      width: "100%",
                      padding: "0px 5px",
                      marginTop: "30px",
                    }}
                  />
                )}
              </section>
              <div className="d-flex justify-content-between align-items-end">
                <FormControl
                  sx={{ marginTop: "10px", width: "60%" }}
                  component="fieldset"
                  variant="standard"
                  size="small"
                >
                  <FormGroup>
                    {[RulesType.importFile, RulesType.importDNCList].includes(watch("rules.ruleType")) && (
                      <RadioGroup
                        aria-label="additionalAttributes"
                        name="additionalAttributes"
                        value={selectedAdditionalAttribute}
                        onChange={handleAdditionalAttributesRadioChange}
                      >
                        <FormControlLabel
                          value="clearAll"
                          control={<Radio />}
                          label="None"
                        />
                        <FormControlLabel
                          value="deleteFiles"
                          control={<Radio />}
                          label="Delete Files after import"
                        />
                        <FormControlLabel
                          value="isMoveFiles"
                          control={<Radio />}
                          label="Move Files after import"
                        />
                        {selectedAdditionalAttribute === "isMoveFiles" && (
                          <Controller
                            name="serverDetails.additionalAttributes.moveFiles.folderName"
                            control={control}
                            defaultValue={watch(
                              "serverDetails.additionalAttributes.moveFiles.folderName"
                            )}
                            rules={{ required: "Folder Name is required" }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Folder Name *"
                                variant="outlined"
                                size="small"
                                error={
                                  !!errors?.serverDetails?.additionalAttributes
                                    ?.moveFiles?.folderName
                                }
                                sx={{ width: "100%", padding: "5px 5px" }}
                              />
                            )}
                          />
                        )}
                      </RadioGroup>
                    )}
                    {[RulesType.exportContactList, RulesType.exportDNCList].includes(watch("rules.ruleType")) && (
                      <>
                        <FormControlLabel
                          value="zipFiles"
                          control={
                            <Checkbox
                              onChange={(_e, checked) => {
                                setSelectedAdditionalAttributeExport({
                                  ...selectedAdditionalAttributeExport,
                                  zipFiles: checked,
                                });
                                setValue("serverDetails.additionalAttributes", {
                                  ...selectedAdditionalAttributeExport,
                                  zipFiles: checked,
                                });
                              }}
                            />
                          }
                          label="Zip files"
                          checked={selectedAdditionalAttributeExport.zipFiles}
                        />
                        <FormControlLabel
                          value="encryptFiles"
                          control={
                            <Checkbox
                              onChange={(_e, checked) => {
                                setSelectedAdditionalAttributeExport({
                                  ...selectedAdditionalAttributeExport,
                                  encryptFiles: checked,
                                });
                                setValue("serverDetails.additionalAttributes", {
                                  ...selectedAdditionalAttributeExport,
                                  encryptFiles: checked,
                                });
                              }}
                            />
                          }
                          disabled={!canWeEncryptExportOperation}
                          label="Encrypt files"
                          checked={
                            selectedAdditionalAttributeExport.encryptFiles
                          }
                        />
                      </>
                    )}
                  </FormGroup>
                </FormControl>
                <div>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{ color: "white", backgroundColor: "#1b2c48" }}
                    onClick={testConnectionHandler}
                  >
                    Test Connection
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="m-2 text"></div>
            <Paper
              elevation={2}
              sx={{ margin: "10px 5px 5px 5px", padding: "10px" }}
            >
              <Controller
                name="rules.ruleType"
                control={control}
                render={({ field }) => (
                  <RadioGroup row {...field} value={watch("rules.ruleType")}>
                    <FormControlLabel
                      value={RulesType.importFile}
                      control={<Radio />}
                      label="Import Contact List"
                    />
                    <FormControlLabel
                      value={RulesType.exportContactList}
                      control={<Radio />}
                      label="Export Contact List"
                    />
                    <FormControlLabel
                      value={RulesType.importDNCList}
                      control={<Radio />}
                      label="Import DNC List"
                    />
                    <FormControlLabel
                      value={RulesType.exportDNCList}
                      control={<Radio />}
                      label="Export DNC List"
                    />
                  </RadioGroup>
                )}
              />
              <div className="row">
                {watch("rules.ruleType") === RulesType.importFile && (
                  <>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <FormControl sx={{ width: "100%" }} size="small">
                        <InputLabel id="demo-select-small-label">
                          File Format Operation
                        </InputLabel>
                        <Select
                          {...register("rules.importFile.fileFormatOperation", {
                            required: true,
                          })}
                          label="File Format Operation"
                          value={
                            watch("rules.importFile.fileFormatOperation") || ""
                          }
                        >
                          {FileFormatOperationTypeList.map((list, i) => (
                            <MenuItem value={list.value} key={i}>
                              {list.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <TextField
                        label="File name format *"
                        {...register("rules.importFile.fileFormatName", {
                          required: true,
                        })}
                        value={watch("rules.importFile.fileFormatName") || ""}
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%", padding: "0px 5px" }}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <Controller
                        name="rules.importFile.contactList"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            size="small"
                            disabled={
                              watch("rules.importFile.appendType") ===
                              AppendType.newlistsonly
                            }
                            sx={{ width: "100%", padding: "0px 5px" }}
                            options={contactListItems}
                            getOptionLabel={(option) => option.name}
                            {...field}
                            value={
                              contactListItems.find(
                                (contact) =>
                                  contact.name ===
                                  watch("rules.importFile.contactList")
                              ) || null
                            }
                            onChange={(_event, newValue) => {
                              setValue(
                                "rules.importFile.contactListId" as keyof IConfiguration,
                                newValue?.id
                              );
                              setValue(
                                "rules.importFile.phoneColumns" as keyof IConfiguration,
                                newValue?.phoneColumns
                              );
                              setValue(
                                "rules.importFile.contactList" as keyof IConfiguration,
                                newValue?.name as string
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Contact List *" />
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <FormControl
                        sx={{ width: "100%", padding: "0px 5px" }}
                        size="small"
                      >
                        <Controller
                          name="rules.importFile.appendType"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              disablePortal
                              size="small"
                              sx={{ width: "100%", padding: "0px 5px" }}
                              {...field}
                              options={AppendTypeList}
                              getOptionLabel={(option) => option.displayName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  label="Append Type *"
                                />
                              )}
                              value={
                                AppendTypeList.find(
                                  (appendType) =>
                                    appendType.value ===
                                    watch("rules.importFile.appendType")
                                ) || null
                              }
                              onChange={(_, newValue) => {
                                setValue(
                                  "rules.importFile.appendType" as keyof IConfiguration,
                                  newValue?.value! as string
                                );
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                    {watch("rules.importFile.appendType") ===
                      AppendType.newlistsonly && (
                      <>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                          <div className="mt-2 text">
                            Contact List Configuration
                          </div>
                          <div className="row">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3">
                              <span className="text">
                                Trim leading/trailing whitespace
                              </span>
                              <Controller
                                name="rules.importFile.contactListConfiguration.trimWhitespace"
                                control={control}
                                render={({ field }) => (
                                  <Switch
                                    {...field}
                                    size="small"
                                    disabled={watch(
                                      "rules.importFile.contactListConfiguration.useImportTemplate"
                                    )}
                                    checked={field.value} // Use field.value to reflect the form value
                                  />
                                )}
                              />
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3">
                              <div className="mb-2 d-flex">
                                <FormControl
                                  sx={{ width: "100%" }}
                                  size="small"
                                >
                                  <Controller
                                    name="rules.importFile.contactListConfiguration.division.id"
                                    control={control}
                                    rules={{ required: "Division is required" }}
                                    render={({ field }) => (
                                      <Autocomplete
                                        {...field}
                                        onChange={(_event, newValue) => {
                                          setValue(
                                            "rules.importFile.contactListConfiguration.division.id" as keyof IConfiguration,
                                            newValue?.id || ""
                                          );
                                        }}
                                        value={
                                          domainList.find(
                                            (domain) =>
                                              domain.id === watch(field.name)
                                          ) || null
                                        }
                                        disablePortal
                                        size="small"
                                        sx={{ width: "100%" }}
                                        options={domainList}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            size="small"
                                            label="Division *"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </div>

                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3">
                              <span className="text">
                                Use Specification File
                              </span>
                              <Controller
                                name="rules.importFile.contactListConfiguration.useSpecificationFile"
                                control={control}
                                render={({ field }) => (
                                  <Switch
                                    {...field}
                                    size="small"
                                    checked={field.value} // Use field.value to reflect the form value
                                  />
                                )}
                              />
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3">
                              <div className="mb-2 d-flex">
                                <FormControl
                                  sx={{ width: "100%" }}
                                  size="small"
                                >
                                  <Controller
                                    name="rules.importFile.fileSpecificationTemplateId"
                                    disabled={
                                      !watch(
                                        "rules.importFile.contactListConfiguration.useSpecificationFile"
                                      )
                                    }
                                    control={control}
                                    rules={{
                                      required:
                                        "fileSpecificationTemplateId is required",
                                    }}
                                    render={({ field }) => (
                                      <Autocomplete
                                        {...field}
                                        onChange={(_event, newValue) => {
                                          setValue(
                                            "rules.importFile.fileSpecificationTemplateId" as keyof IConfiguration,
                                            newValue?.id || ""
                                          );
                                        }}
                                        value={
                                          fileSpecTemplateList.find(
                                            (fileTemplateList) =>
                                              fileTemplateList.id ===
                                              watch(field.name)
                                          ) || null
                                        }
                                        disablePortal
                                        size="small"
                                        sx={{ width: "100%" }}
                                        options={fileSpecTemplateList}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            size="small"
                                            label="File Specification Template *"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3">
                              <span className="text">Use Import Template</span>
                              <Controller
                                name="rules.importFile.contactListConfiguration.useImportTemplate"
                                control={control}
                                render={({ field }) => (
                                  <Switch
                                    {...field}
                                    size="small"
                                    checked={field.value} // Use field.value to reflect the form value
                                  />
                                )}
                              />
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3">
                              <div className="mb-2 d-flex">
                                <FormControl
                                  sx={{ width: "100%" }}
                                  size="small"
                                >
                                  <Controller
                                    name="rules.importFile.importTemplateId"
                                    disabled={
                                      !watch(
                                        "rules.importFile.contactListConfiguration.useImportTemplate"
                                      )
                                    }
                                    control={control}
                                    rules={{
                                      required: "importTemplateId is required",
                                    }}
                                    render={({ field }) => (
                                      <Autocomplete
                                        {...field}
                                        onChange={(_event, newValue) => {
                                          setValue(
                                            "rules.importFile.importTemplateId" as keyof IConfiguration,
                                            newValue?.id || ""
                                          );
                                        }}
                                        value={
                                          importTemplateList.find(
                                            (importTemplate) =>
                                              importTemplate.id ===
                                              watch(field.name)
                                          ) || null
                                        }
                                        disablePortal
                                        size="small"
                                        sx={{ width: "100%" }}
                                        options={importTemplateList}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            size="small"
                                            label="Import Template *"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </div>
                            {!watch(
                              "rules.importFile.contactListConfiguration.useImportTemplate"
                            ) && (
                              <>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                  <span className="text">
                                    Upload contacts from a CSV or Excel file
                                  </span>
                                  <input
                                    type="file"
                                    ref={fileInputRef}
                                    className="form-control form-control-sm mt-1"
                                    onChange={handleFileUpload}
                                    accept=".csv, .xlsx, .xls"
                                  />
                                </div>
                                {excelData.length > 0 && (
                                  <>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                      <TableContainer
                                        sx={{ maxHeight: 350 }}
                                        component={Paper}
                                      >
                                        <Table stickyHeader size="small">
                                          <TableHead>
                                            <TableRow>
                                              {Object.keys(excelData[0]).map(
                                                (header, index) => (
                                                  <TableCell key={index}>
                                                    {header}
                                                  </TableCell>
                                                )
                                              )}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {excelData.map((row, rowIndex) => (
                                              <TableRow key={rowIndex}>
                                                {Object.values(row).map(
                                                  (cell, cellIndex) => (
                                                    <TableCell key={cellIndex}>
                                                      {cell}
                                                    </TableCell>
                                                  )
                                                )}
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {!watch(
                          "rules.importFile.contactListConfiguration.useImportTemplate"
                        ) && (
                          <>
                            {showPhoneMappingCols() && (
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                <div className="text mt-1">
                                  Contact List Columns
                                </div>
                                <div className="text-500 mt-1">
                                  Phone Columns
                                </div>
                                {phoneFields.map((row, index) => (
                                  <div className="row" key={row.id}>
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-1">
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                      >
                                        <Controller
                                          name={`rules.importFile.contactListConfiguration.phoneColumns.${index}.columnName`}
                                          control={control}
                                          rules={{
                                            required: "Column is required",
                                          }}
                                          render={({ field }) => (
                                            <Autocomplete
                                              disablePortal
                                              size="small"
                                              sx={{ width: "100%" }}
                                              {...field}
                                              value={watch(field.name) || ""}
                                              onChange={(_event, newValue) => {
                                                setValue(
                                                  field.name as keyof IConfiguration,
                                                  newValue as string
                                                );
                                              }}
                                              options={excelHeaderCols}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Column *"
                                                  size="small"
                                                />
                                              )}
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-1">
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                      >
                                        <Controller
                                          name={`rules.importFile.contactListConfiguration.phoneColumns.${index}.type`}
                                          control={control}
                                          rules={{
                                            required: "Type is required",
                                          }}
                                          render={({ field }) => (
                                            <Autocomplete
                                              disablePortal
                                              size="small"
                                              sx={{ width: "100%" }}
                                              {...field}
                                              onChange={(_event, newValue) => {
                                                setValue(
                                                  field.name as keyof IConfiguration,
                                                  newValue?.value || ""
                                                );
                                              }}
                                              value={
                                                PhoneTypes.find(
                                                  (phoneTypes) =>
                                                    phoneTypes.value ===
                                                    watch(field.name)
                                                ) || null
                                              }
                                              getOptionLabel={(option) =>
                                                option.displayValue
                                              }
                                              options={PhoneTypes}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Type *"
                                                  size="small"
                                                />
                                              )}
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-1">
                                      <FormControl
                                        sx={{ width: "100%" }}
                                        size="small"
                                      >
                                        <Controller
                                          name={`rules.importFile.contactListConfiguration.phoneColumns.${index}.callableTimeColumn`}
                                          control={control}
                                          render={({ field }) => (
                                            <Autocomplete
                                              disablePortal
                                              size="small"
                                              sx={{ width: "100%" }}
                                              {...field}
                                              value={watch(field.name) || ""}
                                              onChange={(_event, newValue) => {
                                                setValue(
                                                  field.name as keyof IConfiguration,
                                                  newValue as string
                                                );
                                              }}
                                              options={excelHeaderCols}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Time Zone Column"
                                                  size="small"
                                                />
                                              )}
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 mt-1">
                                      {phoneFields.length === index + 1 && (
                                        <IconButton
                                          onClick={() =>
                                            phoneAppend({
                                              columnName: "",
                                              type: "",
                                            })
                                          }
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      )}
                                      {phoneFields.length !== index + 1 && (
                                        <IconButton
                                          onClick={() => phoneRemove(index)}
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {watch("rules.ruleType") === RulesType.exportContactList && (
                  <>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <Controller
                        name="rules.exportContactList.campaignId"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            size="small"
                            sx={{ width: "100%" }}
                            options={campaignList}
                            getOptionLabel={(option) => option.name}
                            {...field}
                            value={
                              campaignList.find(
                                (campaign) =>
                                  campaign.id ===
                                  watch("rules.exportContactList.campaignId")
                              ) || null
                            }
                            onChange={(_event, newValue) => {
                              setValue(
                                "rules.exportContactList.campaignId" as keyof IConfiguration,
                                newValue?.id
                              );
                              setValue(
                                "rules.exportContactList.contactListId" as keyof IConfiguration,
                                ""
                              );
                              setValue(
                                "rules.exportContactList.phoneColumns" as keyof IConfiguration,
                                [] as any
                              );
                              setValue(
                                "rules.exportContactList.contactList" as keyof IConfiguration,
                                "" as string
                              );
                              if (newValue) {
                                const contact = contactListItems.find(
                                  (contact) =>
                                    contact.id === newValue.contactList.id
                                );
                                if (contact) {
                                  setExportContactListItems([contact]);
                                } else {
                                  setExportContactListItems([]);
                                }
                              } else {
                                setExportContactListItems(contactListItems);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label="Campaign"
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <Controller
                        name="rules.exportContactList.contactList"
                        rules={{ required: "Contact List is required" }}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            size="small"
                            sx={{ width: "100%" }}
                            options={exportContactListItems}
                            getOptionLabel={(option) => option.name}
                            {...field}
                            value={
                              exportContactListItems.find(
                                (contact) =>
                                  contact.name ===
                                  watch("rules.exportContactList.contactList")
                              ) || null
                            }
                            onChange={(_event, newValue) => {
                              setValue(
                                "rules.exportContactList.contactListId" as keyof IConfiguration,
                                newValue?.id
                              );
                              setValue(
                                "rules.exportContactList.phoneColumns" as keyof IConfiguration,
                                newValue?.phoneColumns
                              );
                              setValue(
                                "rules.exportContactList.contactList" as keyof IConfiguration,
                                newValue?.name as string
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label="Contact List *"
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                    {/*<div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <TextField
                        label="Output file name format"
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%" }}
                        value={watch(
                          "rules.exportContactList.outputFileFormat"
                        )}
                        {...register(
                          "rules.exportContactList.outputFileFormat",
                          {
                            required: true,
                          }
                        )}
                      />
                        </div>*/}
                  </>
                )}

                {watch("rules.ruleType") === RulesType.importDNCList && (
                  <>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <FormControl sx={{ width: "100%" }} size="small">
                        <InputLabel id="demo-select-small-label">
                          File Format Operation
                        </InputLabel>
                        <Select
                          {...register("rules.importDNCList.fileFormatOperation", {
                            required: true,
                          })}
                          label="File Format Operation"
                          value={
                            watch("rules.importDNCList.fileFormatOperation") || ""
                          }
                        >
                          {FileFormatOperationTypeList.map((list, i) => (
                            <MenuItem value={list.value} key={i}>
                              {list.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <TextField
                        label="File name format *"
                        {...register("rules.importDNCList.fileFormatName", {
                          required: true,
                        })}
                        value={watch("rules.importDNCList.fileFormatName") || ""}
                        variant="outlined"
                        size="small"
                        sx={{ width: "100%", padding: "0px 5px" }}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <Controller
                        name="rules.importDNCList.contactListId"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            size="small"
                            disabled={
                              watch("rules.importDNCList.appendType") ===
                              AppendType.newlistsonly
                            }
                            sx={{ width: "100%", padding: "0px 5px" }}
                            options={dncListItems}
                            getOptionLabel={(option) => option.name}
                            {...field}
                            value={
                              dncListItems.find(
                                (contact) =>
                                  contact.id ===
                                  watch("rules.importDNCList.contactListId")
                              ) || null
                            }
                            onChange={(_event, newValue) => {
                              setValue(
                                "rules.importDNCList.contactListId" as keyof IConfiguration,
                                newValue?.id
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="DNC List *" />
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <FormControl
                        sx={{ width: "100%", padding: "0px 5px" }}
                        size="small"
                      >
                        <Controller
                          name="rules.importDNCList.appendType"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              disablePortal
                              size="small"
                              sx={{ width: "100%", padding: "0px 5px" }}
                              {...field}
                              options={AppendTypeListForDNC}
                              getOptionLabel={(option) => option.displayName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  label="Append Type *"
                                />
                              )}
                              value={
                                AppendTypeListForDNC.find(
                                  (appendType) =>
                                    appendType.value ===
                                    watch("rules.importDNCList.appendType")
                                ) || null
                              }
                              onChange={(_, newValue) => {
                                setValue(
                                  "rules.importDNCList.appendType" as keyof IConfiguration,
                                  newValue?.value! as string
                                );
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                    {(watch("rules.importDNCList.appendType") ===
                      AppendTypeForDNC.newlistsonly) && (
                      <>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                          <div className="mt-2 text">
                            DNC List Configuration
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                              <div className="mb-2 d-flex">
                                  <TextField
                            label="Custom Exclusion Column *"
                            {...register("rules.importDNCList.contactListConfiguration.customExclusionColumn", {
                              required: true,
                            })}
                            value={watch("rules.importDNCList.contactListConfiguration.customExclusionColumn") || ""}
                            variant="outlined"
                            size="small"
                            sx={{ width: "100%", padding: "0px 5px" }}
                          />
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                              <div className="mb-2 d-flex">
                                <FormControl
                                  sx={{ width: "100%" }}
                                  size="small"
                                >
                                  <Controller
                                    name="rules.importDNCList.contactListConfiguration.division.id"
                                    control={control}
                                    rules={{ required: "Division is required" }}
                                    render={({ field }) => (
                                      <Autocomplete
                                        {...field}
                                        onChange={(_event, newValue) => {
                                          setValue(
                                            "rules.importDNCList.contactListConfiguration.division.id" as keyof IConfiguration,
                                            newValue?.id || ""
                                          );
                                        }}
                                        value={
                                          domainList.find(
                                            (domain) =>
                                              domain.id === watch(field.name)
                                          ) || null
                                        }
                                        disablePortal
                                        size="small"
                                        sx={{ width: "100%" }}
                                        options={domainList}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            size="small"
                                            label="Division *"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                              <span className="text">
                                Upload contacts from a CSV or Excel file
                              </span>
                              <input
                                type="file"
                                ref={fileInputRef}
                                className="form-control form-control-sm mt-1"
                                onChange={handleFileUpload}
                                accept=".csv, .xlsx, .xls"
                              />
                            </div>
                            {excelData.length > 0 && (
                              <>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                  <TableContainer
                                    sx={{ maxHeight: 350 }}
                                    component={Paper}
                                  >
                                    <Table stickyHeader size="small">
                                      <TableHead>
                                        <TableRow>
                                          {Object.keys(excelData[0]).map(
                                            (header, index) => (
                                              <TableCell key={index}>
                                                {header}
                                              </TableCell>
                                            )
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {excelData.map((row, rowIndex) => (
                                          <TableRow key={rowIndex}>
                                            {Object.values(row).map(
                                              (cell, cellIndex) => (
                                                <TableCell key={cellIndex}>
                                                  {cell}
                                                </TableCell>
                                              )
                                            )}
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {showDNCCols() && (
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                <div className="text mt-1">
                                  DNC List Columns
                                </div>
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                        <FormControl
                                      sx={{ width: "100%" }}
                                      size="small"
                                    >
                                          <Controller
                                            name="rules.importDNCList.contactListConfiguration.customExclusionColumns"
                                            control={control}
                                            rules={{ required: "DNC Upload column is required" }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                  {...field}
                                                  multiple
                                                  options={excelHeaderCols}
                                                  getOptionLabel={(option) => option}
                                                  value={field.value}
                                                  onChange={(event, newValue) => field.onChange(newValue)}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      variant="outlined"
                                                      label="DNC Upload Columns *"
                                                    />
                                                  )}
                                                />
                                            )}
                                          />
                                        </FormControl>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                    <FormControl
                                      sx={{ width: "100%" }}
                                      size="small"
                                    >
                                          <Controller
                                            name="rules.importDNCList.contactListConfiguration.expirationDateTimeColumn"
                                            control={control}
                                            render={({ field }) => (
                                              <Autocomplete
                                                  {...field}
                                                  options={excelHeaderCols}
                                                  getOptionLabel={(option) => option}
                                                  value={field.value}
                                                  onChange={(event, newValue) => field.onChange(newValue)}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      variant="outlined"
                                                      label="DNC Expiration Column"
                                                    />
                                                  )}
                                                />
                                            )}
                                          />
                                        </FormControl>
                                    </div>
                                </div>
                        </div>
                        )}
                      </>
                    )}
                    {(watch("rules.importDNCList.appendType") ===
                      AppendTypeForDNC.appendonly) && (
                      <>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                          <div className="mt-2 text">
                            DNC List Configuration
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                              <span className="text">
                                Upload contacts from a CSV or Excel file
                              </span>
                              <input
                                type="file"
                                ref={fileInputRef}
                                className="form-control form-control-sm mt-1"
                                onChange={handleFileUpload}
                                accept=".csv, .xlsx, .xls"
                              />
                            </div>
                            {excelData.length > 0 && (
                              <>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                  <TableContainer
                                    sx={{ maxHeight: 350 }}
                                    component={Paper}
                                  >
                                    <Table stickyHeader size="small">
                                      <TableHead>
                                        <TableRow>
                                          {Object.keys(excelData[0]).map(
                                            (header, index) => (
                                              <TableCell key={index}>
                                                {header}
                                              </TableCell>
                                            )
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {excelData.map((row, rowIndex) => (
                                          <TableRow key={rowIndex}>
                                            {Object.values(row).map(
                                              (cell, cellIndex) => (
                                                <TableCell key={cellIndex}>
                                                  {cell}
                                                </TableCell>
                                              )
                                            )}
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {showDNCCols() && (
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                <div className="text mt-1">
                                  DNC List Columns
                                </div>
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                        <FormControl
                                      sx={{ width: "100%" }}
                                      size="small"
                                    >
                                          <Controller
                                            name="rules.importDNCList.contactListConfiguration.customExclusionColumns"
                                            control={control}
                                            rules={{ required: "DNC Upload column is required" }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                  {...field}
                                                  multiple
                                                  options={excelHeaderCols}
                                                  getOptionLabel={(option) => option}
                                                  value={field.value}
                                                  onChange={(event, newValue) => field.onChange(newValue)}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      variant="outlined"
                                                      label="DNC Upload Columns *"
                                                    />
                                                  )}
                                                />
                                            )}
                                          />
                                        </FormControl>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                    <FormControl
                                      sx={{ width: "100%" }}
                                      size="small"
                                    >
                                          <Controller
                                            name="rules.importDNCList.contactListConfiguration.expirationDateTimeColumn"
                                            control={control}
                                            render={({ field }) => (
                                              <Autocomplete
                                                  {...field}
                                                  options={excelHeaderCols}
                                                  getOptionLabel={(option) => option}
                                                  value={field.value}
                                                  onChange={(event, newValue) => field.onChange(newValue)}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      variant="outlined"
                                                      label="DNC Expiration Column"
                                                    />
                                                  )}
                                                />
                                            )}
                                          />
                                        </FormControl>
                                    </div>
                                </div>
                        </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {watch("rules.ruleType") === RulesType.exportDNCList && (
                  <>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <Controller
                        name="rules.exportDNCList.campaignId"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            size="small"
                            sx={{ width: "100%" }}
                            options={campaignList}
                            getOptionLabel={(option) => option.name}
                            {...field}
                            value={
                              campaignList.find(
                                (campaign) =>
                                  campaign.id ===
                                  watch("rules.exportDNCList.campaignId")
                              ) || null
                            }
                            onChange={(_event, newValue) => {
                              setValue(
                                "rules.exportDNCList.campaignId" as keyof IConfiguration,
                                newValue?.id
                              );
                              if (newValue) {
                                const currentCampaign = campaignList.find(
                                  (camp) =>
                                    camp.id === newValue.id
                                );
                                if (currentCampaign) {
                                  const currentCampaignDncLists = currentCampaign.dncLists.map( item => item.id );
                                  if(currentCampaignDncLists) {
                                    const filteredDncList = dncListItems.find(
                                      (list) => currentCampaignDncLists.includes(list.id)
                                    );
                                    if (filteredDncList) {
                                      setExportDNCItems([filteredDncList]);
                                    } else {
                                      setExportDNCItems([]);
                                    }
                                  } else {
                                    setExportDNCItems([]);
                                  }
                                } else {
                                  setExportDNCItems([]);
                                }
                              } else {
                                setExportDNCItems(contactListItems);
                              }
                              setExportDNCItems(dncListItems);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label="Campaign"
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-30">
                      <Controller
                        name="rules.exportDNCList.contactList"
                        rules={{ required: "DNC List is required" }}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            size="small"
                            sx={{ width: "100%" }}
                            options={exportDNCItems}
                            getOptionLabel={(option) => `${option.name}`}
                            {...field}
                            value={
                              exportDNCItems.find(
                                (contact) =>
                                  contact.id ===
                                  watch("rules.exportDNCList.contactListId")
                              ) || null
                            }
                            onChange={(_event, newValue) => {
                              setValue(
                                "rules.exportDNCList.contactListId" as keyof IConfiguration,
                                newValue?.id
                              );
                              setValue(
                                "rules.exportDNCList.contactList" as keyof IConfiguration,
                                newValue?.name as string
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label="DNC List *"
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
            </Paper>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mtw-30 mb-2 d-flex justify-content-end last-row-button">
            <Button
              variant="contained"
              size="medium"
              sx={{ marginRight: "20px" }}
              onClick={() => {
                navigate("../" + RoutesName.jobsview);
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              size="medium"
              sx={{
                color: "white",
                backgroundColor: "#1b2c48",
                marginRight: "5px",
              }}
              /*disabled={!isValid || !isSFTPCredsValid}*/
              disabled={disableForm()}
              type="submit"
            >
              Save Job
            </Button>
          </div>
        </div>
      </form>
      {getSnackDetails.open && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={getSnackDetails.open}
          autoHideDuration={5000}
          onClose={(_event, reason?: string) => {
            if (reason === "clickaway") {
              return;
            }
            setSnackDetails({ open: false, message: <></>, severity: "error" });
          }}
        >
          <Alert
            severity={getSnackDetails.severity as AlertColor}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {getSnackDetails.message as ReactNode}
          </Alert>
        </Snackbar>
      )}
    </Paper>
  );
};

export default Configuration;
