import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// Util
import { getStorageItem } from '../services/applicationStorage';

const PrivateRoute = () => {
  const cspToken = getStorageItem('purecloud-csp-token', true, sessionStorage);
  return (
    cspToken ? <Outlet/> : <Navigate to='/login' />
  );
}

export default PrivateRoute;