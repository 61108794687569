// Global CSS
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Default React
import ReactDOM from "react-dom/client";

// Application
import ApplicationRouter from "./routers/AppRouter";

// Utility
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
// if (process.env.REACT_APP_CUSTOM_ENV.trim() === "development") {
//   root.render(
//     <React.StrictMode>
//       <ApplicationRouter />
//     </React.StrictMode>
//   );
// } else {
  root.render(<ApplicationRouter />);
// }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();