import { useEffect, useState } from "react";
import Close from "@mui/icons-material/CloseRounded";
import DateTime from "./dateTimePicker";
import RepeatView from "./repeat";
import OccurrenceView from "./occurrence";
import { cronExpression } from "./cronExperssion";
import ConfirmDailog from "../../../dialogs/confirmAlert/confirmAlert";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const SchedulerDialog = (props) => {
  const [repeatOn, setRepeatOn] = useState(true);
  const [templateName, setTemplateName] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [endDateType, setEndtDateType] = useState("");
  const [repeatEvery, setRepeatEvery] = useState(1);
  const [repeatOccurrence, setRepeatOccurence] = useState(1);
  const [dayType, setDayType] = useState("Daily");
  const [selectedDays, setSelectedDays] = useState("");
  const [description, setDescription] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [endDateCalculation, setEndDateCalculation] = useState(moment());

  useEffect(() => {
    setTemplateName(
      props.templateName && typeof props.templateName === "object"
        ? props.templateName.value
        : props.templateName
    );
    const isScheduleData = props.schedulerData;
    if (!isScheduleData) {
      return;
    }
    setStartDate(isScheduleData.startDate);
    setDescription(isScheduleData.description);
    if (isScheduleData.isRecursive) {
      setEndDateCalculation(isScheduleData.endDate);
      setRepeatOccurence(isScheduleData.repeatOccurrence);
      setRepeatEvery(isScheduleData.repeatEvery);
      setDayType(isScheduleData.repeat);
      setRepeatOn(true);
      setSelectedDays(isScheduleData.selectedDay);
      setEndtDateType(isScheduleData.endDateType);
    } else {
      isInitialRender();
    }
  }, [props]);

  const isInitialRender = () => {
    setEndDateCalculation(moment());
    setRepeatOccurence(1);
    setRepeatEvery(1);
    setDayType("Daily");
    setRepeatOn(true);
    setSelectedDays("");
    setEndtDateType("Never");
  };

  const handleRepeatChange = (e) => {
    setRepeatOn(e.target.checked);
  };

  const handleClose = () => {
    props.handleClose();
    setRepeatOn(true);
  };

  const handleCronExp = () => {
    const cronExp = cronExpression(
      dayType,
      startDate,
      endDateCalculation,
      Number(repeatEvery),
      Number(repeatOccurrence),
      selectedDays,
      repeatOn,
      endDateType
    );
    if (repeatOn && endDateType === "Never") {
      cronExp.endDate = null;
    } else if (
      repeatOn &&
      moment(cronExp.startDate).isAfter(moment(cronExp.endDate))
    ) {
      return setOpenAlert(true);
    }
    cronExp.description = description;
    cronExp.endDateType = endDateType;
    props.toSaveData(cronExp);
  };

  return (
    <Dialog open={props.dailogOpen} disableEnforceFocus className="date-picker">
      <DialogTitle>
        Scheduler
        <Close
          style={{ float: "right", cursor: "pointer" }}
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent style={{ overflowY: "auto" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Time Zone"
                value={templateName}
                disabled={true}
                variant="outlined"
              />
            </div>
            <div
              className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8"
              style={{ marginTop: "15px" }}
            >
              <DateTime
                size="small"
                labelName={"Schedule Date"}
                selectedDate={(e) => {
                  setStartDate(e);
                  setEndDateCalculation(e);
                }}
                startDate={startDate}
              ></DateTime>
            </div>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 d-flex align-items-center"
              style={{ marginTop: "15px" }}
            >
              <>
                {/* <span className="ml-2">Recursive</span>
                <Switch
                  size="small"
                  checked={repeatOn}
                  onChange={handleRepeatChange}
                  inputProps={{ "aria-label": "controlled" }}
                  color={"primary"}
                /> */}
              </>
            </div>
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              style={{ marginTop: "15px" }}
            >
              <TextField
                label="Description"
                multiline
                rows={3}
                value={description}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {repeatOn && (
          <div className="container">
            <RepeatView
              dayType={(e) => {
                setDayType(e);
              }}
              selectedDays={(e) => {
                setSelectedDays(e);
              }}
              repeatEvery={(e) => {
                setRepeatEvery(e);
              }}
              repeatEve={repeatEvery}
              selectedDay={dayType}
              sltdDays={selectedDays}
            ></RepeatView>
            {/* <OccurrenceView
              repeatOccurrence={(e) => {
                setRepeatOccurence(e);
              }}
              selectDate={endDateCalculation}
              selectedDate={(e) => {
                setEndDateCalculation(e);
              }}
              repeatOcc={repeatOccurrence}
              endDateType={endDateType}
              setEndtDateType={(e) => {
                setEndtDateType(e);
              }}
            ></OccurrenceView> */}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCronExp}>Save</Button>
      </DialogActions>
      <ConfirmDailog
        open={openAlert}
        title={"Warning"}
        confirm={() => {
          setOpenAlert(false);
        }}
        subject={
          "Please select the End date should be greater than Start date."
        }
        handleClose={() => {
          setOpenAlert(false);
        }}
      ></ConfirmDailog>
    </Dialog>
  );
};

export default SchedulerDialog;
