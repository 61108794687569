import EditIcon from "@mui/icons-material/Edit";
import PlayCircleOutline from "@mui/icons-material/PlayCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Alert,
  AlertColor,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Snackbar,
} from "@mui/material";
import cronstrue from "cronstrue";
import { useState, useEffect, ReactNode } from "react";
import "./JobsView.scss";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../../common/constants";
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowSelectionModel,
  GridValueGetterParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { getStorageItem } from "../../services/applicationStorage";
import {
  deleteJobs,
  disableEventRuleForJobService,
  enableEventRuleJobService,
  getJobList,
  runContactListJobService,
} from "../../services/purecloud";
import FeedIcon from "@mui/icons-material/Feed";
import { IJobLog } from "../../models";
import useJobLogsStore from "../../state-management/jobLogs/store";
import moment from "moment";

const JobsView = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<any>([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isDeleteDialogOpen, setDeleteDialogStatus] = useState<boolean>(false);
  const [isIndividualDeleteDialogOpen, setIndividualDeleteDialogOpen] =
    useState<boolean>(false);
  const [deleteIds, setDeleteIds] = useState<
    Array<{ orgId: string; jobId: string }>
  >([] as Array<{ orgId: string; jobId: string }>);
  const [isFormDataLoading, setFormDataLoading] = useState<boolean>(false);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>();
  const [getSnackDetails, setSnackDetails] = useState({
    open: false,
    message: (<></>) as ReactNode,
    severity: "success",
  });
  const { setJobLogs } = useJobLogsStore();

  const orgId = getStorageItem("organization-id", true, sessionStorage);
  /**
   * This hook is used for intialize all intial data via api call
   */
  useEffect(() => {
    getJobs();
  }, []);

  /**
   * This method is used to fetch the jobs
   */
  const getJobs = async () => {
    try {
      setFormDataLoading(true);
      const response = await getJobList();
      setRows(
        response?.data?.map((v) => ({ ...v, isInstantJobRunning: false })) || []
      );

      setFormDataLoading(false);
    } catch (err) {
      console.error(err);
      setRows([]);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  /**
   * This method is used to set job logs and redirect to job logs screen
   * @param joblogs array of job logs
   * @returns void
   */
  const handleRedirectToJobLogsScreen = (jobLogs: Array<IJobLog>) => () => {
    setJobLogs(jobLogs);
    navigate(`/home/${RoutesName.joblogs}`);
  };

  /**
   * This method is used to edit individual job
   * @param id id of the job to be edited
   * @returns void
   */
  const handleIndividualEditClick = (id) => () => {
    navigate(`/home/${RoutesName.configuration}?jobId=${id}`);
  };

  /**
   * This method is used to enable event bridge rule for individual job
   * @param id id of the job
   * @returns void
   */
  const handleRunContactListJobClick = (jobId) => async () => {
    const configuration = {
      orgId: getStorageItem("organization-id", true, sessionStorage),
      jobId,
    };

    try {
      setRows(
        rows.map((row) =>
          row.id === jobId ? { ...row, isInstantJobRunning: true } : row
        )
      );

      await runContactListJobService(configuration);

      const snackDetails = {
        open: true,
        message: <>Contact List Job Started Successfully</>,
        severity: "success",
      };
      setRows(
        rows.map((row) =>
          row.id === jobId ? { ...row, isInstantJobRunning: false } : row
        )
      );

      setSnackDetails(snackDetails);
    } catch (err) {
      setRows(
        rows.map((row) =>
          row.id === jobId ? { ...row, isInstantJobRunning: false } : row
        )
      );

      console.error("handleRunContactListJobClick Failed : ", err);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };

  /**
   * This method is used to disable event bridge rule the individual job
   * @param id id of the job
   * @returns void
   */
  const handleDisableEventRuleClick = (jobId) => async () => {
    const configuration = {
      orgId: getStorageItem("organization-id", true, sessionStorage),
      jobId,
    };

    try {
      await disableEventRuleForJobService(configuration);
      setRows(
        rows.map((row) =>
          row.id === jobId ? { ...row, isScheduleEventRuleEnabled: false } : row
        )
      );

      const snackDetails = {
        open: true,
        message: <>Event Rule is Disabled Successfully</>,
        severity: "success",
      };

      setSnackDetails(snackDetails);
    } catch (err) {
      console.error("handleDisableEventRuleClick Failed : ", err);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };

  /**
   * This method is used to enable event bridge rule the individual job
   * @param id id of the job
   * @returns void
   */
  const handleEnableEventRuleClick = (jobId) => async () => {
    const configuration = {
      orgId: getStorageItem("organization-id", true, sessionStorage),
      jobId,
    };

    try {
      await enableEventRuleJobService(configuration);
      setRows(
        rows.map((row) =>
          row.id === jobId ? { ...row, isScheduleEventRuleEnabled: true } : row
        )
      );

      const snackDetails = {
        open: true,
        message: <>Event Rule is Enabled Successfully</>,
        severity: "success",
      };

      setSnackDetails(snackDetails);
    } catch (err) {
      console.error("handleEnableEventRuleClick Failed : ", err);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };

  /**
   * This method is used to delete individual job
   * @param id id of the job to be deleted
   * @returns void
   */
  const handleIndividualDeleteClick = (id) => async () => {
    setDeleteIds([{ orgId, jobId: id }]);
    setIndividualDeleteDialogOpen(true);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  /**
   * This method is triggered when delete bulk popup is closed
   */
  const handleIndividualDeleteClose = () => {
    setDeleteIds([]);
    setIndividualDeleteDialogOpen(false);
  };

  /**
   * This method is used to delete the Jobs
   */
  const deleteIndividualJobHandler = async () => {
    try {
      setFormDataLoading(true);
      setIndividualDeleteDialogOpen(false);
      await deleteJobs(deleteIds);
      const snackDetails = {
        open: true,
        message: <>Deleted Job Successfully</>,
        severity: "success",
      };

      setSnackDetails(snackDetails);
      setFormDataLoading(false);
      const selectedIDs = deleteIds.map((v) => v.jobId);
      setRows((r) => r.filter((x) => !selectedIDs.includes(x.id)));
    } catch (err) {
      console.error("individual deleteJobs Failed : ", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };

  /**
   * This method is triggered when delete bulk popup is closed
   */
  const handleClose = () => {
    setDeleteDialogStatus(false);
  };

  /**
   * This method is used to delete bulk the Jobs
   */
  const deleteJobHandler = async () => {
    try {
      setFormDataLoading(true);
      setDeleteDialogStatus(false);
      const ids = new Set(rowSelectionModel);
      const deleteIds = Array.from(ids).map((id) => ({
        orgId,
        jobId: id as string,
      }));
      await deleteJobs(deleteIds);
      const snackDetails = {
        open: true,
        message: <>Deleted Jobs(s) Successfully</>,
        severity: "success",
      };

      setSnackDetails(snackDetails);
      setFormDataLoading(false);
      const selectedIDs = deleteIds.map((v) => v.jobId);
      setRows((r) => r.filter((x) => !selectedIDs.includes(x.id)));
    } catch (err) {
      console.error("deleteJobs Failed : ", err);
      setFormDataLoading(false);
      setSnackDetails({
        open: true,
        message: <>Please try again</>,
        severity: "error",
      });
    }
  };

  /**
   * This method is used to generate the custom toolbar for data grid
   * @param props any
   * @returns custom toolbar
   */
  function CustomToolbar(props) {
    // const { setRows, setRowModesModel } = props;

    const handleDelete = () => {
      setDeleteDialogStatus(true);
    };

    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button color="primary" startIcon={<AutorenewIcon />} onClick={getJobs}>
          Refresh
        </Button>
        <Button
          color="primary"
          startIcon={<DeleteIcon />}
          onClick={handleDelete}
          disabled={!rowSelectionModel?.length}
        >
          Delete
        </Button>
        <Button
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => navigate(`/home/${RoutesName.configuration}`)}
        >
          New
        </Button>
      </GridToolbarContainer>
    );
  }

  const defaultWidthColumns = 50;
  const columns = [
    // {
    //   field: "id",
    //   headerName: "Job Id",
    // },
    {
      field: "jobName",
      headerName: "Job Name",
      minWidth: defaultWidthColumns,
      flex: 1,
    },
    // {
    //   field: "serverName",
    //   headerName: "Server Name",
    //   minWidth: defaultWidthColumns,
    //   flex: 1,
    // },
    {
      field: "type",
      headerName: "Type of Job",
      minWidth: defaultWidthColumns,
      flex: 1,
    },
    {
      field: "user",
      headerName: "User",
      minWidth: defaultWidthColumns,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Created Date",
      minWidth: defaultWidthColumns,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value ? moment(params.value).format("MM/DD/YYYY, h:mm:ss a") : "N/A"}`
      },
    },
    {
      field: "latestAppliedDate",
      headerName: "Last Run Date",
      minWidth: defaultWidthColumns,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value ? moment(params.value).format("MM/DD/YYYY, h:mm:ss a") : "N/A"}`
      },
     
    },
    {
      field: "latestStatus",
      headerName: "Latest Status",
      minWidth: defaultWidthColumns,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.latestStatus,
    },
    {
      field: "cronText",
      headerName: "Schedule",
      minWidth: defaultWidthColumns,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.cronExp ? params.row.cronText : "N/A",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      getActions: ({
        id,
        row: {
          isScheduleEventRuleEnabled,
          isSchedule,
          jobLog,
          isInstantJobRunning,
        },
      }) => {
        return [
          isInstantJobRunning ? (
            <GridActionsCellItem
              icon={<CircularProgress size={20} />}
              label="Start"
              title="Run Job"
              className="textPrimary"
            />
          ) : (
            <GridActionsCellItem
              icon={<PlayCircleOutline />}
              label="Start"
              title="Run Job"
              className="textPrimary"
              onClick={handleRunContactListJobClick(id)}
              color="inherit"
            />
          ),
          isScheduleEventRuleEnabled ? (
            <GridActionsCellItem
              icon={<BlockIcon />}
              label="Disable"
              title="Disable Job"
              className="textPrimary"
              disabled={!isSchedule}
              onClick={handleDisableEventRuleClick(id)}
              color="inherit"
            />
          ) : (
            <GridActionsCellItem
              icon={<PlayArrowIcon />}
              label="Enable"
              title="Enable Job"
              className="textPrimary"
              disabled={!isSchedule}
              onClick={handleEnableEventRuleClick(id)}
              color="inherit"
            />
          ),
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title="Edit"
            className="textPrimary"
            onClick={handleIndividualEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<FeedIcon />}
            label="Job Logs"
            title="Job Logs"
            className="textPrimary"
            onClick={handleRedirectToJobLogsScreen(jobLog)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            title="Delete"
            onClick={handleIndividualDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }} className="jobs-view">
      <div className="m-2 text">Job(s) View</div>
      <Box
        sx={{
          height: 580,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFormDataLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Job(s)?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the job(s)?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={deleteJobHandler} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isIndividualDeleteDialogOpen}
          onClose={handleIndividualDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Job?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the job?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleIndividualDeleteClose}>Cancel</Button>
            <Button onClick={deleteIndividualJobHandler} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
        />
      </Box>
      {getSnackDetails.open && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={getSnackDetails.open}
          autoHideDuration={5000}
          onClose={(_event, reason?: string) => {
            if (reason === "clickaway") {
              return;
            }
            setSnackDetails({ open: false, message: <></>, severity: "error" });
          }}
        >
          <Alert
            severity={getSnackDetails.severity as AlertColor}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {getSnackDetails.message as ReactNode}
          </Alert>
        </Snackbar>
      )}
    </Paper>
  );
};

export default JobsView;
