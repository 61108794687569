import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Configuration
import config from "../config";

// Util
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

// Route Components
const PureCloudLogin = lazy(() =>
  import("../components/login/purecloud/PureCloudLogin")
);
const PureConnectLogin = lazy(() =>
  import("../components/login/pureconnect/PureConnectLogin")
);
const Home = lazy(() => import("../components/home/Home"));
const Unauthorized = lazy(() =>
  import("../components/unauthorized/Unauthorized")
);

import { RoutesName } from "../common/constants";
// Don't lazy load initial application entry.
import Main from "../components/Main";
import Configuration from "../components/Configuration/Configuration";
import JobsView from "../components/JobsView/JobsView";
import JobLogs from "../components/JobLogs/JobLogs";
import Settings from "../components/Settings/Settings";
import ProtectedHelp from "../components/ProtectedHelp/ProtectedHelp";
import AddServer from "../components/AddServer/AddServer";
import Help from "../components/Help/Help";
import IndexView from "../wizard/Index";
import InstallView from "../wizard/Install";
import SummaryView from "../wizard/summary";

const ApplicationRouter = ({}) => {
  // Login Provider Determined
  const LoginPage =
    config.product === "purecloud"
      ? PureCloudLogin
      : config.product === "pureconnect"
      ? PureConnectLogin
      : "pureengage";

  const LazyLoadedRoutesFallback = () => {
    return <div>Loading...</div>;
  };

  return (
    <BrowserRouter>
      <Suspense fallback={LazyLoadedRoutesFallback()}>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route path="/" exact={true} element={<IndexView />} />
            <Route path="/index" exact={true} element={<IndexView />} />
            <Route path="/install" exact={true} element={<InstallView />} />
            <Route path="/summary" exact={true} element={<SummaryView />} />
            <Route path="/main" element={<Main />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path={"/" + RoutesName.help} element={<Help />} />
          </Route>
          <Route path="/home" element={<PrivateRoute />}>
            <Route path="" element={<Home />}>
              <Route path={RoutesName.jobsview} element={<JobsView />} />
              <Route
                path={RoutesName.configuration}
                element={<Configuration />}
              />
              <Route path={RoutesName.joblogs} element={<JobLogs />} />
              <Route path={RoutesName.settings} element={<Settings />} />
              <Route path={RoutesName.addserver} element={<AddServer />} />
              <Route path={RoutesName.help} element={<ProtectedHelp />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default ApplicationRouter;
