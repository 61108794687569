import React, { useEffect, useState } from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
} from "@mui/material";
import DayListView from "./daysList";
import DaysSelector from './daysSelector'
const RepeatView = (props) => {
  const [dayType, setDayType] = useState("Daily");
  const [repeatEvery, setRepeatEvery] = useState(1);
  const [selectedDay, setSelectedDays] = useState("");

  useEffect(() => {
    if (props.repeatEve || props.selectedDay) {
      setRepeatEvery(props.repeatEve);
      setDayType(props.selectedDay);
      setSelectedDays(props.sltdDays);
    }
  }, [props]);

  const handleDayChange = (event) => {
    setDayType(event.target.value);
    setSelectedDays("");
    props.dayType(event.target.value);
    if (event.target.value === "Minutes") {
      setRepeatEvery(5);
      props.repeatEvery(5);
    } else if (
      event.target.value === "Days" ||
      event.target.value === "Weekly"
    ) {
      setRepeatEvery(1);
      props.repeatEvery(1);
    }
  };

  const handleRepeatEvery = (event) => {
    if (dayType === "Weekly") {
      if (event.target.value >= 1 && event.target.value <= 5) {
        setRepeatEvery(event.target.value);
        props.repeatEvery(event.target.value);
      }
      return;
    }
    if (dayType === "Minutes") {
      if (event.target.value >= 5) {
        setRepeatEvery(event.target.value);
        props.repeatEvery(event.target.value);
      }
      return;
    }
    if (event.target.value >= 0 && event.target.value <= 31) {
      setRepeatEvery(event.target.value);
      props.repeatEvery(event.target.value);
    }
  };

  const selectedDays = (e) => {
    props.selectedDays(e);
  };

  return (
    <div>
      <div className="row">
        <div
          className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8"
          style={{ marginTop: "15px" }}
        >
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-label">Repeat</InputLabel>
            <Select
              value={dayType}
              label="Repeat"
              onChange={handleDayChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              className="selectDay"
            >
              <MenuItem value={"Minutes"}>Minutes</MenuItem>
              <MenuItem value={"Daily"}>Daily</MenuItem>
              <MenuItem value={"Weekly"}>Weekly</MenuItem>
              <MenuItem value={"Monthly"}>Monthly</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div
          className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
          style={{ marginTop: "15px" }}
        >
          <TextField
            type="number"
            size="small"
            variant="outlined"
            label="Repeat Every"
            fullWidth
            onChange={handleRepeatEvery}
            value={repeatEvery}
          />
        </div>

        {dayType === "Weekly" && (
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            style={{ marginTop: "15px" }}
          >
            <label className="font-size">Repeat On</label>
            <DayListView
              dayType={dayType}
              selectedDays={selectedDays}
              repeatEvery={repeatEvery}
            ></DayListView>
          </div>
        )}
        {dayType === "Monthly" && (
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            style={{ marginTop: "15px" }}
          >
            <DaysSelector selectedDates={selectedDays}></DaysSelector>
          </div>
        )}
      </div>
    </div>
  );
};

export default RepeatView;
