import moment from 'moment'

const conExp = ["*", "*", "*", "*", "*", "*"];
let newStartDate = moment();
let newEndDate = moment();
let cronExpinWord = ""
export const cronExpression = (dayType, startDate, endDate, repeatEvery, repeatOccurrence, weeks, repeatOn, endDateType) => {
    newStartDate = moment.utc(moment(startDate));
    newEndDate = moment.utc(moment(endDate));
    if (repeatOn) {
        switch (dayType) {
            case 'Minutes':
                minExpression(repeatEvery, repeatOccurrence, endDateType);
                break;
            case 'Daily':
                dailyExpression(repeatEvery, repeatOccurrence, endDateType);
                break;
            case 'Weekly':
                weeklyExpression(repeatEvery, repeatOccurrence, weeks, endDateType);
                break;
            case 'Monthly':
                monthlyExpression(repeatEvery, repeatOccurrence, weeks, endDateType);
                break;
        }
    } else {
        nonRecursiveExpression()
    }
    return { cronExpression: convertToString(conExp), startDate: newStartDate.toISOString(), endDate: newEndDate.toISOString(), repeat: dayType, repeatOccurrence: repeatOccurrence, repeatEvery: repeatEvery, isRecursive: repeatOn, selectedDay: weeks, cronWordExp: cronExpinWord }
}

const minExpression = (repeatEvery, repeatOccurrence, endDateType) => {
    conExp[0] = `0/${repeatEvery}`;
    conExp[1] = "*";
    conExp[2] = `*`;
    conExp[3] = "*";
    conExp[4] = "?";
    conExp[5] = "*";
    cronExpinWord = `Run every ${repeatEvery} minute(s) UTC`

    if (endDateType === "After") {
        newEndDate = moment(newStartDate)
        newEndDate.add(repeatEvery * repeatOccurrence, 'days');
    }
    return conExp;
}

const dailyExpression = (repeatEvery, repeatOccurrence, endDateType) => {
    conExp[0] = newStartDate.minutes();
    conExp[1] = newStartDate.hours();
    conExp[2] = `*/${repeatEvery}`;
    conExp[3] = "*";
    conExp[4] = "?";
    conExp[5] = "*";
    cronExpinWord = `At ${newStartDate.format("hh:mm A")} UTC, every ${repeatEvery} day(s), starting on day ${newStartDate.date()} of ${newStartDate.format('MMMM')} month`

    if (endDateType === "After") {
        newEndDate = moment(newStartDate)
        newEndDate.add(repeatEvery * repeatOccurrence, 'days');
    }
    return conExp;
}

const weeklyExpression = (repeatEvery, repeatOccurrence, weeks, endDateType) => {
    const weekDays = {
        sun: 1,
        mon: 2,
        tue: 3,
        wed: 4,
        thu: 5,
        fri: 6,
        sat: 7,
    }
    const days = weeks.replaceAll(' ', ',')
    conExp[0] = newStartDate.minutes();
    conExp[1] = newStartDate.hours();
    conExp[2] = "?";
    conExp[3] = "*";
    // conExp[4] = days;
    conExp[4] = days? (repeatEvery === 1 ? days: `${weekDays[days.toLowerCase()]}#${repeatEvery}`): days;
    conExp[5] = "*";
    if (endDateType === "After") {
        newEndDate = moment(newStartDate)
        newEndDate.add(repeatOccurrence, 'weeks');
    }
    
    cronExpinWord = `At ${newStartDate.format("hh:mm A")} UTC, only on ${days} for every ${repeatEvery} week(s)`
    return conExp;
}

const monthlyExpression = (repeatEvery, repeatOccurrence, weeks, endDateType) => {
    // const repeatOnDate = Number(weeks);
    conExp[0] = newStartDate.minutes();
    conExp[1] = newStartDate.hours()
    // conExp[2] = repeatOnDate;
    conExp[2] = weeks ? weeks : '*';
    conExp[3] = repeatEvery === 1 ? '*' : `*/${repeatEvery}`;
    conExp[4] = '?';
    conExp[5] = "*";
    if (endDateType === "After") {
        newEndDate = moment(newStartDate)
        newEndDate.add(repeatEvery * repeatOccurrence, 'months')._d;
    }

    cronExpinWord = `At ${newStartDate.format("hh:mm A")} UTC, on day ${weeks==='L' ? 'last day': weeks} for every ${repeatEvery} month(s) starting from ${newStartDate.format('MMMM')}`;
    return conExp;
}

const nonRecursiveExpression = () => {
    conExp[0] = newStartDate.minutes();
    conExp[1] = newStartDate.hours()
    conExp[2] = newStartDate.date();
    conExp[3] = newStartDate.month() + 1;
    conExp[4] = '?';
    conExp[5] = newStartDate.year();
    newEndDate = moment(newStartDate);
    cronExpinWord = `At ${newStartDate.format("hh:mm A")} UTC, on ${newStartDate.date()} ${newStartDate.format('MMMM')}, ${newStartDate.year()}`;
    return conExp;
}

const convertToString = (arrExp) => { return arrExp.join(' ') }