export const getParameterByName = (name) => {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
    const regex = new RegExp("[\\#&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.hash)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const fetchWrapper = async (url, init) => {
    const maxRetries = 3
    let fetchOk = false, i = 0, response, correlationId, retryAfterMs, json
    while (!fetchOk && i < maxRetries) {
        response = await fetch(url, init)
        for (let pair of response.headers.entries()) {
            if (pair[0] === 'inin-correlation-id')
                correlationId = pair[1]

            if (pair[0] === 'retry-after')
                retryAfterMs = pair[1] * 1000
        }
        try {
            json = await response.json()
        } catch (error) {
            // api didnt return a json, we can ignore this
        }

        if (response.ok) fetchOk = true
        else {
            // looping if server error only
            if (response.status >= 500) {
                await sleep(1000)
                i++
            } else i = maxRetries
        }
    }

    return fetchOk ?
        { data: json, correlationId, status: response.status }
        : Promise.reject({ ...json, correlationId, retryAfterMs, status: response.status })
}

export default {
    getParameterByName,
    fetchWrapper,
    sleep
}