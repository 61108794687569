import React, { useState, useEffect } from "react";
import { ChildDiv, MainDiv, ParentDiv, TitleDiv, WizardContent, Button } from "../styles";
import Header from "../common/header";
import Loader from "../common/loader";
import ProgressBar from "../common/progressBar";
import WizardApp from "../common/scripts/wizard-app";
import WelcomeGreet from "../common/welcome-greet/welcome-greet";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../services/applicationStorage";

const InstallView = (props) => {
    let [isLoading, setLoader] = useState(false);
    const startApp = new WizardApp();
    const navigate = useNavigate();
    

    const InstallConfiguration = () => {
       
        startApp.installConfigurations().then((done) => {
            navigate('/summary');
        }).catch((err) => {
            console.error(err)
            if (err && err.body && err.body.message) {
                navigate('/summary', {state: {error: err.body.message}});
            } else if(err){
                navigate('/summary', {state: {error: err}});
            } else {
                navigate('/summary');
            }
        });
    };

    useEffect(()=>{
        if(!sessionStorage.getItem('isInstallationChecked')) {
            console.log("navigating to index")
            navigate('/index');
        }
    },[])

    return (
        <ParentDiv>
            <div id="background"></div>
            <Header tail="Install"></Header>

            <WizardContent>
                {(isLoading) &&
                    <Loader></Loader>
                }
                <WelcomeGreet tabTitle="Install"></WelcomeGreet>
            </WizardContent>

            <ProgressBar tabName="install"></ProgressBar>

            <MainDiv>
                <ChildDiv type="install">
                    <TitleDiv>Create Roles</TitleDiv>
                    <span>Create roles specifically to provide access to the app.</span>
                    <span ><b className="sjs-install-role">Outbound List Manager for SFTP</b>
                        <span>
                            <ul className="sjs-install-ul">
                                <li>Privileges to access the back end APIs</li>                             
                            </ul>
                           
                        </span>
                    </span>
                  
                   
                </ChildDiv>
                <ChildDiv type="install">
                    <TitleDiv>Create OAuth Client</TitleDiv>
                    <span>Create OAuth Client for server-to-server communication.</span>
                </ChildDiv>            
                <Button position="right" onClick={InstallConfiguration} className="mt-1">
                    <span className="install-submit">Submit</span>
                    <i className="fa-solid fa-circle-plus"></i>
                </Button>
            </MainDiv>
        </ParentDiv >
    )
}

export default InstallView;